import { Form, Input, InputNumber, message, Modal, Select, Switch } from "antd";
import { LLMProductForm } from "../type";
import { useEffect, useState } from "react";
import { addModel, editModel } from "@/api/product";

export function EditModal({
  open,
  onClose,
  type,
  data,
}: {
  open: boolean;
  onClose: () => void;
  type: "add" | "edit";
  data: LLMProductForm;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const addProduct = async () => {
    const fields = form.getFieldsValue();
    try {
      setLoading(true);
      await addModel({
        ...fields,
        isPrivate: fields.isPrivate ? 1 : 0,
        tags: fields.tags?.join(",") ?? "",
      });
      message.success("新增成功");
      onClose();
    } catch (error) {
      console.error(error);
      message.error("新增失败");
    } finally {
      setLoading(false);
    }
  };

  const editProduct = async () => {
    const fields = form.getFieldsValue();
    try {
      setLoading(true);
      await editModel({
        ...fields,
        id: data.id,
        isPrivate: fields.isPrivate ? 1 : 0,
        tags: fields.tags.join(","),
      });
      message.success("编辑成功");
      onClose();
    } catch (error) {
      console.error(error);
      message.error("编辑失败");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type === "edit") {
      form.setFieldsValue({
        ...data,
        tags: data?.tags ? data.tags.split(",") : [],
      });
      setIsPrivate(data.isPrivate == 1);
    } else {
      form.resetFields();
    }
  }, [data, form, type]);

  const handleSubmit = () => {
    if (type === "add") {
      addProduct();
    } else {
      editProduct();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={type === "add" ? "新增产品" : "编辑产品"}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <div style={{ maxHeight: "540px", overflowY: "auto", paddingRight: 16 }}>
        <Form form={form} layout="vertical">
          <Form.Item
            label="产品名称"
            name="modelName"
            rules={[{ required: true }]}
          >
            <Input disabled={type === "edit"} />
          </Form.Item>
          <Form.Item
            label="展示名称"
            name="displayName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="产品描述" name="description">
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item label="默认停止词" name="defaultStops">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="标签" name="tags">
            <Select mode="tags" />
          </Form.Item>
          <Form.Item label="Context Size" name="contextSize">
            <InputNumber controls={false} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="排序" name="rank">
            <InputNumber controls={false} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="状态" name="status">
            <Select>
              <Select.Option value={1}>服务中</Select.Option>
              <Select.Option value={2}>已删除</Select.Option>
              <Select.Option value={3}>待上线</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="私有模型" name="isPrivate">
            <Switch
              checked={isPrivate}
              onChange={(value) => setIsPrivate(value)}
            />
          </Form.Item>
          {isPrivate && (
            <Form.Item label="用户id" name="userId">
              <Input />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
}
