import { queryAccount, queryUserInfo } from "@/api/user";
import { Button, Input, Space, Tag } from "antd";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import styles from "./style.module.css";

export function SelectApplyUsers({
  applyUsers,
  onChange,
}: {
  applyUsers: string[];
  onChange: (value: string[]) => void;
}) {
  const [account, setAccount] = useState<string | undefined>(undefined);
  const [addUserInfo, setAddUserInfo] = useState<
    {
      email: string;
      phone: string;
      uuid: string;
    }[]
  >([]);
  const addUser = () => {
    if (account) {
      queryAccount(account).then((res) => {
        onChange([...applyUsers, res.uuid]);
        setAddUserInfo([...addUserInfo, { ...res }]);
        setAccount(undefined);
      });
    }
  };
  const [queryUsers, setQueryUsers] = useState<
    {
      uuid: string;
      email: string;
      phone: string;
    }[]
  >([]);
  const searchUser = debounce(() => {
    if (account) {
      queryUserInfo(account).then((res) => {
        if (Array.isArray(res?.data)) {
          setQueryUsers(
            res.data.map((user: any) => ({
              uuid: user.uuid,
              email: user.email,
              phone: user.phone,
            }))
          );
        }
      });
    } else {
      setQueryUsers([]);
    }
  }, 500);
  useEffect(() => {
    searchUser();
  }, [account]);
  useEffect(() => {
    if (applyUsers.length === 0) {
      setAddUserInfo([]);
    }
  }, [applyUsers]);
  return (
    <div>
      <Space>
        <Input
          value={account}
          onChange={(e) => setAccount(e.target.value)}
          style={{
            width: 280,
          }}
        />
        <Button onClick={addUser}>添加用户</Button>
      </Space>
      {queryUsers.length > 0 && (
        <ul className={styles.userList}>
          {queryUsers.map((user) => (
            <li
              key={user.uuid}
              onClick={() => {
                if (applyUsers.includes(user.uuid)) {
                  return;
                }
                onChange([...applyUsers, user.uuid]);
                setAddUserInfo([...addUserInfo, { ...user }]);
              }}
            >
              {user.email && <span>邮箱: {user.email}</span>}
              {user.phone && <span>手机: {user.phone}</span>}
            </li>
          ))}
        </ul>
      )}
      <div style={{ marginTop: 10 }}>
        {addUserInfo?.map((user) => (
          <Tag
            key={user.uuid}
            closable
            onClose={() => {
              onChange(applyUsers.filter((uuid) => uuid !== user.uuid));
              setAddUserInfo(addUserInfo.filter((u) => u.uuid !== user.uuid));
            }}
          >
            {user.email || user.phone}
          </Tag>
        ))}
      </div>
    </div>
  );
}
