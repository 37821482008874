import { request } from "./fetch";

export function getWalletBilling(params: any) {
  return request({
    url: "/api/v1/admin/wallet/billing",
    query: params,
  });
}

export function getWalletTransaction(params: any) {
  return request({
    url: "/api/v1/admin/wallet/transaction",
    query: params,
  });
}

export function getVoucherTemplates(params: any) {
  return request({
    url: "/api/v1/admin/voucher/templates",
    query: params,
  });
}
// 添加/编辑优惠券模板
export function addEditVoucherTemplate(params: any) {
  return request({
    url: "/api/v1/admin/voucher/template",
    method: "POST",
    data: params,
  });
}

// 发放优惠券
export function presentVoucher(params: any) {
  return request({
    url: "/api/v1/admin/voucher/present",
    method: "POST",
    data: params,
  });
}

export function getVoucherList(params: any) {
  return request({
    url: "/api/v1/admin/voucher/list",
    query: params,
  });
}

// 撤销优惠券
export function revokeVoucher(id: string) {
  return request({
    url: "/v1/admin/voucher/revoke",
    method: "DELETE",
    query: { id },
  });
}

export function confirmBanUser(params: any): Promise<string> {
  return request({
    url: "/api/v1/admin/user/ban",
    method: "POST",
    data: params,
  });
}

// 用户优惠券使用详情
export function getVoucherUseDetail(params: any) {
  return request({
    url: "/v1/admin/voucher/list",
    query: params,
  });
}

// 查询账单（全部）
export function getBillList(params: any) {
  return request({
    url: "/v1/admin/bill/list",
    query: {
      ...params,
    },
  });
}
