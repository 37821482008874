import { request } from "./fetch";

export function queryAccount(account: string) {
  return request({
    url: "/v1/admin/user-account",
    query: { account },
  });
}

export function queryAccountWithoutCatchErr(account: string) {
  return request({
    url: "/v1/admin/user-account",
    query: { account },
    catchErr: false,
  });
}

// 模糊查询用户信息
export function queryUserInfo(account: string) {
  return request({
    url: "/v1/admin/user/search",
    query: { account },
  });
}


// 查询封禁用户
export function queryBanUser(params: any) {
  return request({
    url: "/v1/admin/user-ban",
    query: params,
  });
}

// 封禁用户
export function banUser(params: any) {
  return request({
    url: "/api/v1/admin/user/ban",
    method: "POST",
    data: params,
  });
}
