import { BusinessLineList } from "@/constants";
import { EditData } from "./AddConfigModal";
import styles from "./style.module.css";
import { Select, Tag } from "antd";
import { useMemo } from "react";

export function SelectSKU({
  editData,
  onChange,
  productCategoryList,
  productList,
}: EditData & {
  productCategoryList: any[];
  productList: any[];
}) {
  const productCategoryOptions = useMemo(() => {
    if (!editData.applyBizType || editData.applyBizType === "") {
      return productCategoryList.map((one) => ({
        label: one.category,
        value: one.category,
      }));
    }
    return productCategoryList
      .filter((one) => one.businessType === editData.applyBizType)
      .map((one) => ({
        label: one.category,
        value: one.category,
      }));
  }, [productCategoryList, editData.applyBizType]);

  const productOptions = useMemo(() => {
    return [
      {
        label: "全部",
        value: "all",
        regionNames: [],
      },
    ].concat(
      productList.map((one) => ({
        label: one.displayName,
        value: one.productId,
        regionNames: one.regionNames,
      }))
    );
  }, [productList]);

  return (
    <div>
      <div className={styles.label2}>业务线</div>
      <Select
        value={editData.applyBizType}
        placeholder="请选择产品线"
        onChange={(value) => {
          onChange({
            ...editData,
            applyBizType: value,
            applyProductCategory: "",
            applyProductsCopy: [],
          });
        }}
        style={{ width: 300 }}
        options={BusinessLineList}
      />
      <div className={styles.label2}>产品分类</div>
      <Select
        value={editData.applyProductCategory}
        placeholder="请选择产品子类"
        style={{ width: 300 }}
        options={productCategoryOptions}
        onChange={(value) => {
          onChange({
            ...editData,
            applyProductCategory: value,
            applyProductsCopy: [],
          });
        }}
      />
      <div className={styles.label2}>
        产品SKU
        <span style={{ color: "#111", fontWeight: 500 }}>
          {" "}
          （如果选择 “全部”， 对整个产品子类生效）
        </span>
      </div>
      <Select
        mode="multiple"
        value={editData.applyProductsCopy}
        placeholder="请选择产品SKU"
        style={{ width: 300 }}
        onChange={(value) => {
          if (Array.isArray(value) && value.includes("all")) {
            onChange({
              ...editData,
              applyProductsCopy: ["all"],
            });
            return;
          }
          onChange({
            ...editData,
            applyProductsCopy: value,
          });
        }}
        showSearch
        filterOption={(input, option: any) => {
          const [label] = option?.key?.split("##") ?? [];
          const match =
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
            (label ?? "").toLowerCase().includes(input.toLowerCase());
          return match;
        }}
        tagRender={(props) => {
          const { label, closable, onClose } = props;
          return (
            <Tag
              closable={closable}
              onClose={onClose}
              style={{ display: "flex", width: 260, margin: 4 }}
            >
              {label}
            </Tag>
          );
        }}
      >
        {productOptions.map((one) => (
          <Select.Option key={`${one.label}##${one.value}`} value={one.value}>
            <div>
              {one.label}
              {Array.isArray(one.regionNames) && one.regionNames.length > 0 && (
                <div>
                  {one.regionNames.map((one) => (
                    <Tag key={one} color="blue">
                      {one}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
