import { Form, Input, Select, DatePicker, Button, Table } from "antd";
import { stateList, transactionChannelList } from "@/constants";
import styles from "../index.module.scss";
import { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { PaginationComp } from "@/components/pagination";
import { getWalletTransaction } from "@/api/beacon";

const { Item } = Form;
const { Option } = Select;

const columns = [
  {
    title: "流水号",
    key: "serialNumber",
    dataIndex: "serialNumber",
  },
  {
    title: "客户Id",
    key: "customerId",
    dataIndex: "customerId",
  },
  {
    title: "交易时间",
    key: "transactionTime",
    dataIndex: "vtransactionTime",
    render: (_: any, record: any) => {
      return (
        <div>
          {dayjs.unix(record?.transactionTime).format("YYYY-MM-DD HH:mm:ss") ||
            ""}
        </div>
      );
    },
  },
  {
    title: "交易渠道",
    key: "transactionChannel",
    dataIndex: "transactionChannel",
    render: (v: string, record: any) => {
      return (
        <div>
          {transactionChannelList.find(
            (item) => item.value === record.transactionChannel
          )?.label || v}
        </div>
      );
    },
  },
  {
    title: "交易金额",
    key: "transactionAmount",
    dataIndex: "transactionAmount",
    render: (_: any, record: any) => {
      return <div>{Number(record.transactionAmount) / 100}</div>;
    },
  },
  {
    title: "钱包余额",
    key: "walletBalance",
    dataIndex: "walletBalance",
    render: (_: any, record: any) => {
      return <div>{Number(record?.walletBalance) / 100}</div>;
    },
  },
  {
    title: "状态",
    key: "state",
    dataIndex: "state",
    render: (v: string) => {
      const item = stateList.find((one) => one.value === v);
      return item ? item.label : v;
    },
  },
  {
    title: "备注",
    key: "note",
    dataIndex: "note",
  },
];

export function CustomerTrade() {
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageParams, setPageParams] = useState({
    pageNum: 1,
    pageSize: 10,
  });
  const [params, setParams] = useState({
    serialNumber: "",
    transactionTimeStart: "",
    transactionTimeEnd: "",
    transactionType: "",
    transactionChannel: "",
    customerId: "",
    state: "",
    dateRange: [] as Dayjs[],
  });
  const fetchData = useCallback(
    (queryParams?: any) => {
      const query = {
        serialNumber: params.serialNumber ?? "",
        transactionChannel: params.transactionChannel ?? "",
        customerId: params.customerId ?? "",
        state: params.state ?? "",
        transactionType: params.transactionType ?? "",
        transactionTimeStart: params.transactionTimeStart
          ? dayjs(params.transactionTimeStart + " 00:00:00").unix()
          : "",
        transactionTimeEnd: params.transactionTimeEnd
          ? dayjs(params.transactionTimeEnd + " 23:59:59").unix()
          : "",
        pageNo: pageParams.pageNum,
        pageSize: pageParams.pageSize,
        ...queryParams,
      };
      setLoading(true);
      getWalletTransaction(query)
        .then((res) => {
          setDataSource(res.data);
          setTotal(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [pageParams, params]
  );
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  // params 变化，重置页码
  useEffect(() => {
    setPageParams({ pageNum: 1, pageSize: 10 });
  }, [params]);
  return (
    <div>
      <div className={styles.common_form}>
        <Form layout="inline">
          <Item label="客户ID">
            <Input
              placeholder="请输入客户ID"
              value={params.customerId}
              onChange={(e) => {
                setParams({ ...params, customerId: e.target.value });
              }}
            />
          </Item>
          <Item label="流水号">
            <Input
              className={styles.input}
              placeholder="请输入产品名称"
              value={params.serialNumber}
              onChange={(e) => {
                setParams({ ...params, serialNumber: e.target.value });
              }}
            />
          </Item>
          <Item label="交易时间">
            <DatePicker.RangePicker
              allowClear={false}
              value={params.dateRange as any}
              onChange={(value) => {
                if (value && value[0] && value[1]) {
                  setParams({
                    ...params,
                    dateRange: [value[0], value[1]],
                    transactionTimeStart: value[0].format("YYYY-MM-DD"),
                    transactionTimeEnd: value[1].format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Item>
          <Item label="交易渠道">
            <Select
              className={styles.select}
              allowClear
              placeholder="请选择"
              value={params.transactionChannel}
              onChange={(value) => {
                setParams({ ...params, transactionChannel: value });
              }}
            >
              {transactionChannelList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Item>
          <Item label="状态">
            <Select
              className={styles.select}
              allowClear
              placeholder="请选择"
              value={params.state}
              onChange={(value) => {
                setParams({ ...params, state: value });
              }}
            >
              {stateList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Item>
          <Item>
            <Button
              type="primary"
              onClick={() => {
                fetchData({ pageNo: 1, pageSize: 10 });
                setPageParams({ pageNum: 1, pageSize: 10 });
              }}
            >
              查询
            </Button>
          </Item>
        </Form>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
          rowKey={(record) => record.serialNumber}
        />
      </div>
      <div className={styles.common_pagination}>
        <PaginationComp
          total={total}
          pageSize={pageParams.pageSize}
          pageNum={pageParams.pageNum}
          onChange={(pageNum, pageSize) => {
            setPageParams({ pageNum, pageSize });
          }}
        />
      </div>
    </div>
  );
}
