import { Button, Form, Input, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import EditStorageProduct from "./editStorageProduct";
import {
  getStorageProducts,
  addEditStorageProductInfo,
  setStorageProductPriceInfo,
} from "@/api/business";
import dayjs from "dayjs";
import EditPowerStoragePrice from "../editPowerStoragePrice";
import { TablePricingColumn } from "@/components/table-pricing";
import { DisDetailModal } from "@/components/table-pricing/DisDetailModal";
import { BusinessType } from "@/constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { hasEditProductResource, hasSetDiscountResource, hasSetPriceResource } from "@/constants/auth";

export default function StorageProductManage() {
  const navigate = useNavigate();
  const { resource } = useSelector((state: RootState) => state.user);
  const [modalInfo, setModalInfo] = useState({
    showModal: false,
    type: "Add",
    storageProductInfo: {},
  });
  const [resultTable, setResultTable] = useState([]);
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 10,
    productId: "",
    productName: "",
  });
  const [editProductBtnLoading, setEditProductBtnLoading] = useState(false);
  const [editPriceBtnLoading, setEditPriceBtnLoading] = useState(false);

  const [pricingModal, setPricingModal] = useState<any>({
    open: false,
    productId: "",
  });

  const [showPrice, setShowPrice] = useState<any>({
    showModal: false,
    type: "Edit",
    powerPriceInfo: {
      productName: "",
      priceId: "1234567890",
      freeCapacity: "",
      chargingModel: "",
      priceByCapacity: "",
      discountRateByCapacity: "",
      discountTimeByCapacityStart: "",
      discountTimeByCapacityEnd: "",
      // priceAfterDiscount: "",
      dayPrice: "",
      weekPrice: "",
      monthPrice: "",
      yearPrice: "",
      isEffect: "",
    },
  });
  function getTableData() {
    getStorageProducts(params).then((res: any) => {
      console.log(res);
      if (res?.data && res.data.length) {
        res.data.forEach((ele: any) => {
          if (
            ele.price &&
            ele.price.chargingModel &&
            ele.price.chargingModel.length === 0
          ) {
            ele.price.chargingModel = ["capacity"];
          }
        });
      }
      setResultTable(res.data || []);
    });
  }
  useEffect(() => {
    getTableData();
  }, []);
  function addStorageProduct(storageProductInfo: any) {
    if (!storageProductInfo) {
      setModalInfo({ showModal: true, type: "Add", storageProductInfo: {} });
    } else {
      setModalInfo({ showModal: true, type: "Edit", storageProductInfo });
    }
  }
  function finishOperate(
    cancelMark: boolean,
    type: string,
    storageProductInfo: any
  ) {
    if (!cancelMark) {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("storageProductInfo:", storageProductInfo);
      setModalInfo({ ...modalInfo, showModal: false });
    } else {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("storageProductInfo:", storageProductInfo);
      setEditProductBtnLoading(true);
      addEditStorageProductInfo({
        productId: storageProductInfo.productId,
        productName: storageProductInfo.productName,
        freeRootfs: storageProductInfo.freeRootfs
          ? Number(storageProductInfo.freeRootfs)
          : 0,
        freeLocalStorage: storageProductInfo.freeLocalStorage
          ? Number(storageProductInfo.freeLocalStorage)
          : 0,
      })
        .then(() => {
          message.success("操作成功");
          setModalInfo({ ...modalInfo, showModal: false });
          getTableData();
        })
        .finally(() => {
          setEditProductBtnLoading(false);
        });
    }
  }
  // function delStorageProductInfoFun(id: string) {
  //   Modal.confirm({
  //     title: "确认",
  //     icon: <ExclamationCircleOutlined />,
  //     content: "是否确认删除数据",
  //     okText: "确认",
  //     cancelText: "取消",
  //     onOk: () => {
  //       delStorageProductInfo({ id }).then(() => {
  //         message.success("操作成功");
  //         getTableData();
  //       });
  //     },
  //   });
  // }
  // function addStoragePrice(powerPriceInfo: any) {
  //   console.log("powerPriceInfo:", powerPriceInfo);
  //   setShowPrice({
  //     ...showPrice,
  //     showModal: true,
  //     powerPriceInfo: {
  //       ...showPrice.powerPriceInfo,
  //       ...powerPriceInfo,
  //       priceByCapacity: powerPriceInfo.chargingModel?.includes("capacity")
  //         ? dealMoney(Number(powerPriceInfo.priceByCapacity), 3)
  //         : "",
  //       priceAfterDiscount: powerPriceInfo.chargingModel?.includes("capacity")
  //         ? dealMoney(Number(powerPriceInfo.priceAfterDiscount), 3)
  //         : "",
  //       dayPrice: powerPriceInfo.chargingModel?.includes("day")
  //         ? dealMoney(Number(powerPriceInfo.dayPrice), 3)
  //         : "",
  //       weekPrice: powerPriceInfo.chargingModel?.includes("week")
  //         ? dealMoney(Number(powerPriceInfo.weekPrice), 3)
  //         : "",
  //       monthPrice: powerPriceInfo.chargingModel?.includes("month")
  //         ? dealMoney(Number(powerPriceInfo.monthPrice), 3)
  //         : "",
  //       yearPrice: powerPriceInfo.chargingModel?.includes("year")
  //         ? dealMoney(Number(powerPriceInfo.yearPrice), 3)
  //         : "",
  //       discountTimeByCapacityStart:
  //         powerPriceInfo.discountTimeByCapacityStart === "0"
  //           ? ""
  //           : powerPriceInfo.discountTimeByCapacityStart,
  //       discountTimeByCapacityEnd:
  //         powerPriceInfo.discountTimeByCapacityEnd === "0"
  //           ? ""
  //           : powerPriceInfo.discountTimeByCapacityEnd,
  //     },
  //   });
  // }
  function finishPrice(cancelMark: boolean, type: string, powerPriceInfo: any) {
    if (!cancelMark) {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("powerPriceInfo:", powerPriceInfo);
      setShowPrice({ ...showPrice, showModal: false });
    } else {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("powerPriceInfo:", powerPriceInfo);
      setEditPriceBtnLoading(true);
      setStorageProductPriceInfo({
        ...powerPriceInfo,
        priceId: [powerPriceInfo.priceId],
        priceByCapacity: powerPriceInfo.chargingModel?.includes("capacity")
          ? Math.round(Number(powerPriceInfo.priceByCapacity) * 100000)
          : "0",
        discountRateByCapacity: powerPriceInfo.chargingModel?.includes(
          "capacity"
        )
          ? powerPriceInfo.discountRateByCapacity
          : 0,
        // priceAfterDiscount: powerPriceInfo.chargingModel?.includes("capacity") ? Number(powerPriceInfo.priceAfterDiscount) * 100 : "0",
        priceAfterDiscount: undefined,
        dayPrice: powerPriceInfo.chargingModel?.includes("day")
          ? Math.round(Number(powerPriceInfo.dayPrice) * 100000)
          : "0",
        weekPrice: powerPriceInfo.chargingModel?.includes("week")
          ? Math.round(Number(powerPriceInfo.weekPrice) * 100000)
          : "0",
        monthPrice: powerPriceInfo.chargingModel?.includes("month")
          ? Math.round(Number(powerPriceInfo.monthPrice) * 100000)
          : "0",
        yearPrice: powerPriceInfo.chargingModel?.includes("year")
          ? Math.round(Number(powerPriceInfo.yearPrice) * 100000)
          : "0",
        discountTimeByCapacityStart: powerPriceInfo.chargingModel.includes(
          "capacity"
        )
          ? dayjs(
            powerPriceInfo.ageingDate[0].format("YYYY-MM-DD") + " 00:00:00"
          )
            .unix()
            .toString()
          : "0",
        discountTimeByCapacityEnd: powerPriceInfo.chargingModel.includes(
          "capacity"
        )
          ? dayjs(
            powerPriceInfo.ageingDate[1].format("YYYY-MM-DD") + " 23:59:59"
          )
            .unix()
            .toString()
          : "0",
      })
        .then(() => {
          message.success("操作成功");
          setShowPrice({ ...showPrice, showModal: false });
          getTableData();
        })
        .finally(() => {
          setEditPriceBtnLoading(false);
        });
    }
  }
  const columns: ColumnsType<any> = [
    {
      title: "产品id",
      key: "productId",
      dataIndex: "productId",
    },
    {
      title: "产品名称",
      key: "productName",
      dataIndex: "productName",
    },
    {
      title: "免费FS（Root Filesystem）",
      key: "freeRootfs",
      dataIndex: "freeRootfs",
      render: (_: any, record: any) => {
        return record.freeRootfs || record.freeRootfs === 0 ? record.freeRootfs + "GB" : "-";
      },
    },
    {
      title: "免费本地存储大小",
      key: "freeLocalStorage",
      dataIndex: "freeLocalStorage",
      render: (_: any, record: any) => {
        return record.freeLocalStorage || record.freeLocalStorage === 0 ? record.freeLocalStorage + "GB" : "-";
      },
    },
    {
      title: "产品价格",
      key: "productPriceInfo",
      dataIndex: "productPriceInfo",
      render: (_: any, record: any) => {
        return (
          <TablePricingColumn
            pricing={record.pricing}
            onClick={() => {
              setPricingModal({
                open: true,
                productId: record.productId,
              });
            }}
          />
        );
      },
    },
    {
      title: "操作",
      width: 250,
      render: (_: any, record: any) => {
        return (
          <div>
            {hasSetPriceResource(resource) && (
              <Button
                style={{ paddingLeft: "0" }}
                type="link"
                onClick={() => {
                  navigate(
                    `/pricing/product?productId=${record.productId}&businessType=${BusinessType.GPU_INSTANCE}`
                  );
                }}
              >
                设置价格
              </Button>
            )}
            {hasSetDiscountResource(resource) && (
              <Button
                type="link"
                onClick={() => {
                  navigate(
                    `/pricing/discount?productId=${record.productId}&productCategory=${record.productCategory}&businessType=${BusinessType.GPU_INSTANCE}`
                  );
                }}
              >
                设置折扣
              </Button>
            )}
            {hasEditProductResource(resource) && (
              <Button type="link" onClick={() => addStorageProduct(record)}>
                修改
              </Button>
            )}
            {/* <Button danger type="link" onClick={() => delStorageProductInfoFun(record.productId)}>删除</Button> */}
          </div>
        );
      },
    },
  ];

  function searchData() {
    getTableData();
  }
  function inputSetParamsText(item: string, e: any, valueType = "") {
    const paramsTmp: any = params;
    paramsTmp[item] = valueType === "value" ? e : e.target.value;
    setParams({ ...paramsTmp });
  }
  return (
    <div style={{ padding: "10px" }}>
      <div>
        {/* <span style={{ marginBottom: "20rem", fontSize: "16px", fontWeight: "bolder" }}>
        存储产品列表
      </span> */}
        <div>
          <div>
            <Form>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="产品id：">
                  <Input
                    onChange={(e: any) => inputSetParamsText("productId", e)}
                    style={{ width: "215px" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="产品名称：">
                  <Input
                    onChange={(e: any) => inputSetParamsText("productName", e)}
                    style={{ width: "200px" }}
                  />
                </Form.Item>
              </div>
              <Button
                onClick={searchData}
                style={{ marginRight: "20px" }}
                type="primary"
              >
                查询
              </Button>
              {/* <Button style={{marginRight: "20px"}} type="primary" onClick={addStorageProduct}>新增</Button> */}
            </Form>
          </div>
          <Table columns={columns} dataSource={resultTable}></Table>
        </div>
      </div>
      {modalInfo.showModal ? (
        <EditStorageProduct
          btnLoading={editProductBtnLoading}
          finishOperate={finishOperate}
          showModal={modalInfo.showModal}
          type={modalInfo.type}
          storageProductInfo={modalInfo.storageProductInfo}
        />
      ) : (
        ""
      )}
      {showPrice.showModal ? (
        <EditPowerStoragePrice
          btnLoading={editPriceBtnLoading}
          finishOperate={finishPrice}
          showModal={showPrice.showModal}
          type={showPrice.type}
          powerPriceInfo={showPrice.powerPriceInfo}
        />
      ) : (
        ""
      )}
      <DisDetailModal
        open={pricingModal.open}
        onClose={() => setPricingModal({ open: false, productId: "" })}
        productId={pricingModal.productId}
        businessType={BusinessType.GPU_INSTANCE}
      />
    </div>
  );
}
