import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
} from "antd";
import styles from "./index.module.css";
import { useCallback, useEffect, useState } from "react";
import { deleteModel, queryModelList } from "@/api/product";
import { PaginationComp } from "@/components/pagination";
import { EditModal } from "./EditModal";
import { useNavigate } from "react-router-dom";
import { TablePricingColumn } from "@/components/table-pricing";
import { DisDetailModal } from "@/components/table-pricing/DisDetailModal";
import { BusinessType } from "@/constants";
import { removeObjectEmptyValues } from "@/utils";
import { hasDeleteProductResource, hasEditProductResource, hasSetDiscountResource, hasSetPriceResource } from "@/constants/auth";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

export function LLMProcut() {
  const navigate = useNavigate();
  const { resource } = useSelector((state: RootState) => state.user);
  const [openDiscountDetail, setOpenDiscountDetail] = useState({
    open: false,
    productId: "",
  });
  const columns: any[] = [
    {
      title: "模型ID",
      dataIndex: "id",
    },
    {
      title: "模型名称",
      dataIndex: "modelName",
    },
    {
      title: "展示名称",
      dataIndex: "displayName",
    },
    {
      title: "模型描述",
      dataIndex: "description",
      render(value: string) {
        return (
          <div
            style={{
              maxWidth: 400,
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      title: (
        <div>
          <div>产品价格 </div>
          <div>input tokens / output tokens</div>
        </div>
      ),
      dataIndex: "pricing",
      render: (value: any) => (
        <TablePricingColumn
          isLLM
          pricing={value}
          onClick={() =>
            setOpenDiscountDetail({ open: true, productId: value.productId })
          }
        />
      ),
    },
    {
      title: "context size",
      dataIndex: "contextSize",
    },
    {
      title: "标签管理",
      dataIndex: "tags",
    },
    {
      title: "私有模型",
      dataIndex: "isPrivate",
      render: (_: boolean, record: any) =>
        record.isPrivate == 1 ? `用户ID: ${record.userId}` : "否",
    },
    {
      title: "排序",
      dataIndex: "rank",
    },
    {
      title: "默认停止词",
      dataIndex: "defaultStops",
      width: 300,
      render(value: string) {
        return (
          <div
            style={{
              maxWidth: 300,
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (value: number) => {
        if (value === 1) {
          return "服务中";
        }
        if (value === 2) {
          return "已删除";
        }
        if (value === 3) {
          return "待上线";
        }
        return "-";
      },
    },
    {
      title: "操作",
      dataIndex: "action",
      fixed: "right",
      render: (_: unknown, record: any) => (
        <Space>
          {hasEditProductResource(resource) && (
            <Button
              type="link"
              onClick={() => {
                setModalOpen({ type: "edit", open: true, data: record });
              }}
            >
              编辑
            </Button>
          )}
          {hasSetPriceResource(resource) && (
            <Button
              type="link"
              onClick={() => {
                navigate(
                  `/pricing/product?productId=${record.productId}&businessType=${BusinessType.MODEL_API}`
                );
              }}
            >
              设置价格
            </Button>
          )}
          {hasSetDiscountResource(resource) && (
            <Button
              type="link"
              onClick={() => {
                navigate(
                  `/pricing/discount?productId=${record.productId}&productCategory=${record.productCategory}&businessType=${BusinessType.MODEL_API}`
                );
              }}
            >
              设置折扣
            </Button>
          )}
          {hasDeleteProductResource(resource) && (
            <Button type="link" danger onClick={() => handleDelete(record.id)}>
              删除
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const [form] = Form.useForm();
  const [pageParams, setPageParams] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [modalOpen, setModalOpen] = useState<{
    type: "add" | "edit";
    open: boolean;
    data: any;
  }>({
    type: "add",
    open: false,
    data: {},
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const searchParams = form.getFieldsValue();
      const res = await queryModelList({
        ...pageParams,
        ...removeObjectEmptyValues(searchParams),
      });
      if (Array.isArray(res.list)) {
        setDataSource(res.list);
        setTotal(res.total);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [pageParams, form]);

  const handleDelete = async (id: string) => {
    Modal.confirm({
      title: "确认删除",
      onOk: async () => {
        const res = await deleteModel(id);
        if (res) {
          message.success("删除成功");
          fetchData();
        }
      },
    });
  };

  useEffect(() => {
    if (!modalOpen.open) {
      fetchData();
    }
  }, [fetchData, modalOpen.open]);

  return (
    <div>
      <div>
        <Breadcrumb
          items={[
            {
              title: "LLM",
            },
            {
              title: "产品管理",
            },
          ]}
        />
      </div>
      <div className={styles.search_container}>
        <div className={styles.search_form}>
          <Form layout="inline" form={form}>
            <Form.Item label="模型名称" name="modelName">
              <Input />
            </Form.Item>
            <Form.Item label="展示名称" name="displayName">
              <Input />
            </Form.Item>
            <Form.Item label="是否私有" name="isPrivate">
              <Select style={{ width: 100 }}>
                <Select.Option value={""}>全部</Select.Option>
                <Select.Option value={1}>是</Select.Option>
                <Select.Option value={0}>否</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="状态" name="status">
              <Select style={{ width: 120 }}>
                <Select.Option value={1}>服务中</Select.Option>
                <Select.Option value={2}>已删除</Select.Option>
                <Select.Option value={3}>待上线</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    setPageParams({ pageIndex: 1, pageSize: 10 });
                  }}
                >
                  搜索
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                    fetchData();
                  }}
                >
                  重置
                </Button>
              </Space>
            </Form.Item>
          </Form>
          <div className={styles.action_container}>
            {hasEditProductResource(resource) && (
              <Button type="primary" onClick={() => setModalOpen({ type: "add", open: true, data: {} })}>
                新增
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.table_container}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
          rowKey="id"
          scroll={{ x: "max-content" }}
        />
      </div>
      <div className={styles.pagination_container}>
        <PaginationComp
          total={total}
          pageNum={pageParams.pageIndex}
          pageSize={pageParams.pageSize}
          onChange={(pageNum, pageSize) => {
            setPageParams({ pageIndex: pageNum, pageSize });
          }}
        />
      </div>
      <DisDetailModal
        open={openDiscountDetail.open}
        onClose={() =>
          setOpenDiscountDetail({ ...openDiscountDetail, open: false })
        }
        productId={openDiscountDetail.productId}
        businessType={BusinessType.MODEL_API}
      />
      <EditModal
        open={modalOpen.open}
        type={modalOpen.type}
        data={modalOpen.data}
        onClose={() => {
          setModalOpen({ ...modalOpen, open: false });
        }}
      />
    </div>
  );
}
