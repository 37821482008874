import icon1 from "../assets/common/node_manager.png";
import icon2 from "../assets/common/agent_manager.png";
import icon3 from "../assets/common/billing.png";
import icon4 from "../assets/common/new_agent.png";
import icon5 from "../assets/common/outgoing_bandwidth.png";

const icons = [icon1, icon2, icon3, icon4, icon5];

export const routes: any[] = [
  {
    label: "Dashboard",
    path: "/dashboard",
    key: "dashboard",
  },
  {
    label: "平台计费",
    path: "/pricing",
    key: "pricing",
    children: [
      {
        label: "产品价格",
        path: "/pricing/product",
        key: "pricing-product",
      },
      {
        label: "折扣管理",
        path: "/pricing/discount",
        key: "pricing-discount",
      },
      {
        label: "交易账单",
        path: "/pricing/bill",
        key: "pricing-bill",
      },
      {
        label: "客户账单",
        path: "/pricing/customer-bill",
        key: "pricing-customer-bill",
      },
      {
        label: "代金券",
        path: "/pricing/voucher",
        key: "pricing-voucher",
      },
      {
        label: "信控额度",
        path: "/pricing/credit",
        key: "pricing-credit",
      },
      {
        label: "余额告警",
        path: "/pricing/balance-warning",
        key: "pricing-balance-warning",
      },
    ],
  },
  {
    label: "参数管理",
    path: "/meta",
    key: "meta",
    children: [
      {
        label: "产品类型",
        path: "/meta/productType",
        key: "meta-productType",
      },
      {
        label: "集群管理",
        key: "clusterManage",
        path: "/meta/clusterManage",
      },
      {
        label: "显卡型号",
        key: "gpuModelManage",
        path: "/meta/gpuModelManage",
      },
    ],
  },
  {
    label: "Model API",
    path: "/model-api",
    key: "model-api",
    children: [
      {
        label: "数据看板",
        path: "/model-api/dashboard",
        key: "model-api-dashboard",
      },
      {
        label: "专属节点",
        path: "/model-api/enterprise",
        key: "model-api-enterprise",
      },
      {
        label: "Admin",
        path: "/model-api/admin",
        key: "model-api-admin",
      },
    ],
  },
  {
    label: "GPU",
    path: "/gpu",
    key: "gpu",
    children: [
      {
        label: "节点管理",
        path: "/gpu/node",
        key: "gpu-node",
      },
      {
        label: "产品管理",
        path: "/gpu/product",
        key: "gpu-product",
        children: [
          {
            label: "GPU 算力",
            key: "gpuInstance",
            path: "/gpu/product/gpuInstance",
          },
          {
            label: "存储",
            key: "storage",
            path: "/gpu/product/storage",
          },
        ],
      },
    ],
  },
  {
    label: "LLM",
    path: "/llm",
    key: "llm",
    children: [
      {
        label: "产品管理",
        path: "/llm/product",
        key: "llm-product",
      },
    ],
  },
  {
    label: "Serverless",
    path: "/serverless",
    key: "serverless",
    children: [
      {
        label: "产品管理",
        path: "/serverless/product",
        key: "serverless-product",
      },
    ],
  },
  {
    label: "Strapi",
    path: "/strapi",
    key: "strapi",
  },
  {
    label: "用户管理",
    path: "/account",
    key: "account",
    children: [
      // {
      //   label: "用户信息",
      //   path: "/account/profile",
      //   key: "account-profile",
      // },
      {
        label: "用户封禁",
        path: "/account/ban",
        key: "account-ban",
      },
    ],
  },
].map((route, index) => ({
  ...route,
  icon: (
    <img
      src={icons[index % icons.length]}
      alt="icon"
      style={{ width: 16, height: 16 }}
    />
  ),
  iconurl: icons[index % icons.length],
  children: route.children?.map((child: any) => ({
    ...child,
    iconurl: icons[index % icons.length],
    children: child.children?.map((grandChild: any) => ({
      ...grandChild,
      iconurl: icons[index % icons.length],
    })),
  })),
}));

// 递归获取获取有权限的路由
// handleShowResource 判断是否有权限
// 保持当前路由的顺序
export function getPermissionRouteList() {
  return routes;
}

export const findRoute = (key: string) => {
  // 递归
  const find = (routes: any[]) => {
    for (const route of routes) {
      if (route.key === key || route.path === key) {
        return route;
      }
      if (route.children) {
        const child: any = find(route.children);
        if (child) {
          return child;
        }
      }
    }
    return null;
  };
  return find(routes);
};
