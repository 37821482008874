import { createPublicBill, getUserInfoByCode } from "@/api/pricing";
import { SimpleTable } from "@/components/simple-table";
import { Button, Form, Input, InputNumber, message, Modal, Space } from "antd";
import Big from "big.js";
import { useEffect, useState } from "react";
import { SwapOutlined } from "@ant-design/icons";
export default function BillingModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [params, setParams] = useState<{
    uuid: string;
    phone: string;
    rechargeAmount: number | null;
    companyAlias: string;
  }>({
    uuid: "",
    phone: "",
    companyAlias: "",
    rechargeAmount: null,
  });

  const [code, setCode] = useState("");
  const [switchType, setSwitchType] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    if (params.rechargeAmount === null) {
      message.error("请输入充值金额");
      return;
    }
    if (params.rechargeAmount === 0) {
      message.error("充值金额不能为0");
      return;
    }
    if (params.phone === "" && params.uuid === "") {
      message.error("请输入充值账号");
      return;
    }
    Modal.confirm({
      title: "确认充值信息",
      content: (
        <div>
          <p>
            用户账号：
            <strong style={{ fontWeight: 700 }}>
              {params.phone ? params.phone : params.uuid}
            </strong>
            {params.companyAlias && (
              <strong style={{ fontWeight: 700 }}>
                {` ${params.companyAlias}`}
              </strong>
            )}
          </p>
          <p>
            充值金额：
            <span style={{ fontWeight: 700, color: "red" }}>
              {params.rechargeAmount}
            </span>
          </p>
        </div>
      ),
      onOk: () => {
        setLoading(true);
        createPublicBill({
          ...params,
          rechargeAmount: Big(params.rechargeAmount || 0)
            .times(100)
            .toNumber(),
        })
          .then(() => {
            message.success("创建成功");
            onClose();
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };
  const handleSearch = () => {
    let verifyCode = code.trim();
    if (!verifyCode) {
      message.error("请输入识别码");
      return;
    }
    if (!verifyCode.startsWith("ppinfra_")) {
      verifyCode = `ppinfra_${verifyCode}`;
    }

    getUserInfoByCode({ code: verifyCode }).then((res) => {
      message.success("查询成功");
      setParams({
        ...params,
        uuid: res.uuid,
        phone: res.phone,
        companyAlias: res.companyAlias,
      });
    });
  };
  useEffect(() => {
    if (open) {
      setParams({
        uuid: "",
        phone: "",
        rechargeAmount: null,
        companyAlias: "",
      });
      setSwitchType(false);
    }
  }, [open]);
  return (
    <Modal
      open={open}
      onCancel={onClose}
      title="创建对公账单/对公充值"
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <Form layout="vertical">
        {switchType ? (
          <Form.Item
            label={
              <div>
                <Space>
                  <span>请输入手机号/uuid充值</span>
                  <Button
                    type="link"
                    onClick={() => setSwitchType(false)}
                    icon={<SwapOutlined />}
                  ></Button>
                </Space>
              </div>
            }
          >
            <div>
              <Input
                placeholder="请输入手机号"
                style={{ width: 300 }}
                value={params.phone}
                onChange={(e) =>
                  setParams({ ...params, phone: e.target.value?.trim() })
                }
              />
              <Input
                placeholder="请输入uuid"
                style={{ width: 300, marginTop: 10 }}
                value={params.uuid}
                onChange={(e) =>
                  setParams({ ...params, uuid: e.target.value?.trim() })
                }
              />
            </div>
          </Form.Item>
        ) : (
          <>
            <Form.Item
              label={
                <div>
                  <Space>
                    <span>请输入银行转账的识别码</span>
                    <Button
                      type="link"
                      onClick={() => setSwitchType(true)}
                      icon={<SwapOutlined />}
                    ></Button>
                  </Space>
                </div>
              }
            >
              <Input
                placeholder="请输入识别码"
                style={{ width: 300 }}
                value={code}
                onChange={(e) => setCode(e.target.value?.trim())}
              />
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={handleSearch}
              >
                查询
              </Button>
            </Form.Item>
            <Form.Item label="用户信息（仅展示）">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <SimpleTable>
                  <tr>
                    <td style={{ width: 100 }}>用户账号</td>
                    <td>{params.phone}</td>
                  </tr>
                  <tr>
                    <td style={{ width: 100 }}>公司别名</td>
                    <td>{params.companyAlias}</td>
                  </tr>
                  <tr>
                    <td style={{ width: 100 }}>uuid</td>
                    <td>{params.uuid}</td>
                  </tr>
                </SimpleTable>
              </div>
            </Form.Item>
          </>
        )}

        <Form.Item label="充值金额(¥)">
          <InputNumber
            min={0}
            precision={2}
            style={{ width: 300 }}
            controls={false}
            value={params.rechargeAmount}
            onChange={(value) =>
              setParams({ ...params, rechargeAmount: value })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
