import { Button, Input, message, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import moment from "moment";
import { getVoucherList, revokeVoucher } from "@/api/beacon";
import Big from "big.js";
import { hasRevokeVoucherResource } from "@/constants/auth";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

const statusList: any = {
  waitValid: "待生效",
  valid: "生效中",
  expired: "已过期",
  used: "已使用",
};

export default function VoucherList({
  templateId = null,
}: {
  templateId: any;
}) {
  const { resource } = useSelector((state: RootState) => state.user);
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
    total: 0,
  });
  const [tableData, setTableData] = useState([]);
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(false);

  const queryData = (params: any) => {
    setLoading(true);
    getVoucherList({ templateId, ...params })
      .then((res: any) => {
        console.log("templateId res:", res);
        setTableData(res.data || []);
        setPageParams({
        pageNo: params.pageNo || 1,
          pageSize: params.pageSize || 10,
          total: res.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "客户Id",
      key: "grantTo",
      dataIndex: "grantTo",
      width: 100,
      render: (_: any, record: any) => {
        return (
          <div>
            <div>{record.grantTo}</div>
            {record.account && (
              <div style={{ fontWeight: 700, marginTop: 4 }}>
                {record.account}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "代金券id",
      key: "id",
      dataIndex: "id",
      width: 100,
    },
    // {
    //   title: '代金模板券id',
    //   key: 'voucherTemplate',
    //   dataIndex: "voucherTemplate",
    //   width: 100
    // },
    {
      title: "代金券名称",
      key: "name",
      dataIndex: "name",
      width: 100,
    },
    {
      title: "生效日期",
      key: "effectDate",
      dataIndex: "effectDate",
      width: 100,
      render: (_: any, record: any) => {
        return (
          <div>
            {record.effectDate && !isNaN(Number(record.effectDate))
              ? moment
                  .unix(Number(record.effectDate))
                  .format("YYYY-MM-DD HH:mm:ss")
              : ""}
          </div>
        );
      },
    },
    {
      title: "失效日期",
      key: "expiryDate",
      dataIndex: "expiryDate",
      width: 100,
      render: (_: any, record: any) => {
        return (
          <div>
            {record.expiryDate && !isNaN(Number(record.expiryDate))
              ? moment
                  .unix(Number(record.expiryDate))
                  .format("YYYY-MM-DD HH:mm:ss")
              : ""}
          </div>
        );
      },
    },
    {
      title: "余额",
      key: "balance",
      dataIndex: "balance",
      width: 100,
      render: (_: any, record: any) => {
        return <div>￥{Number(record.balance) / 10000}</div>;
      },
    },
    {
      title: "原始金额",
      key: "originalValue",
      dataIndex: "originalValue",
      width: 100,
      render: (_: any, record: any) => {
        return <div>￥{Number(record.originalValue) / 10000}</div>;
      },
    },
    {
      title: "状态",
      key: "status",
      dataIndex: "status",
      width: 100,
      render: (_: any, record: any) => {
        return <div>{statusList[record.status] || ""}</div>;
      },
    },
    {
      title: "发放人",
      key: "grantor",
      dataIndex: "grantor",
      width: 100,
    },
    {
      title: "发放时间",
      key: "grantTime",
      dataIndex: "grantTime",
      width: 100,
      render: (_: any, record: any) => {
        return (
          <div>
            {record.grantTime && !isNaN(Number(record.grantTime))
              ? moment
                  .unix(Number(record.grantTime))
                  .format("YYYY-MM-DD HH:mm:ss")
              : ""}
          </div>
        );
      },
    },
    {
      title: "操作",
      key: "action",
      dataIndex: "action",
      width: 100,
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <div>
            {hasRevokeVoucherResource(resource) && (
              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: "确认撤销优惠券",
                    content: (
                      <div style={{ width: 400 }}>
                        <div>
                          用户：{" "}
                          <span style={{ fontWeight: 700 }}>
                            {record.account}
                          </span>
                        </div>
                        <div>
                          金额：{" "}
                          <span
                            style={{
                              fontWeight: 700,
                              color: "red",
                            }}
                          >
                            ${Big(record.originalValue).div(10000).toString()}
                          </span>
                        </div>
                      </div>
                    ),
                    onOk: () => {
                      revokeVoucher(record.id).then(() => {
                        message.success("撤销成功");
                        queryData({ account, ...pageParams, templateId });
                      });
                    },
                  });
                }}
              >
                撤销
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    queryData({ account, ...pageParams, templateId });
  }, [templateId]);
  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "10px 0",
          gap: 10,
        }}
      >
        <Input
          placeholder="客户邮箱/手机号/uuid"
          value={account}
          onChange={(e) => setAccount(e.target.value)}
          style={{ width: 400 }}
        />
        <Button
          onClick={() =>
            queryData({ account, ...pageParams, templateId, pageNo: 1 })
          }
          type="primary"
        >
          搜索
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={tableData}
        scroll={{ x: "max-content" }}
        loading={loading}
        pagination={{
          current: pageParams.pageNo,
          pageSize: pageParams.pageSize,
          total: pageParams.total,
        }}
        onChange={(pagination) => {
          setPageParams({
            pageNo: pagination.current || 1,
            pageSize: pagination.pageSize || 10,
            total: pageParams.total,
          });
          queryData({
            account,
            templateId,
            pageNo: pagination.current,
            pageSize: pagination.pageSize,
          });
        }}
      ></Table>
    </div>
  );
}
