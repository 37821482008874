import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./views/login";
import Layout from "./views/Layout";
import Dashboard from "./views/dashboard";
import Node from "./views/gpu/node";
import { ProductManager } from "./views/pricing/product-manager";
import { CustomerTrade } from "./views/gpu/beacon/customer-trade";
import { CustomerTrade as CustomerTradeZh } from "./views/gpu/beacon/customer-trade-zh";
import { Voucher as VoucherEn } from "./views/gpu/beacon/voucher";
import { Voucher as VoucherZh } from "./views/gpu/beacon/voucher-zh";
import ClusterManage from "./views/gpu/meta/clusterManage";
import { getEnv, globalEnv_global, initEnv, IS_DEV } from "./config.ts";
import { useEffect } from "react";
import CreditLimit from "./views/pricing/credit-limit/index.tsx";
import NoAuth from "./components/no-auth/index.tsx";
import { RootState } from "./store/index.ts";
import { useSelector } from "react-redux";
import BalanceWarning from "./views/pricing/balance-warning/index.tsx";
import { StrapiIframe } from "./views/strapi/index.tsx";
import { Discount } from "./views/pricing/discount/index.tsx";
import { LLMProcut } from "./views/llm/product/index.tsx";
import { ServerlessProcut } from "./views/serverless/product/index.tsx";
import { ProductTypeManager } from "./views/meta/productType/index.tsx";
import PowerProductManageZh from "./views/gpu/meta/business/powerProductManage-zh/index.tsx";
import PowerProductManage from "./views/gpu/meta/business/powerProductManage/index.tsx";
import StorageProductManage from "./views/gpu/meta/business/storageProductManage/index.tsx";
import GpuModelManage from "./views/gpu/meta/business/gpuModelManage/index.tsx";
import CustomerBill from "./views/pricing/customer-bill/index.tsx";
import Profile from "./views/account/profile/index.tsx";
import Ban from "./views/account/ban/index.tsx";

const SubApp = () => {
  return <div id="subapp-container" />;
};

function App() {
  const { global } = getEnv();
  const { resource } = useSelector((state: RootState) => state.user);
  const hasResource = (key: string) => {
    if (IS_DEV) {
      return true;
    }
    return (
      resource.includes(key) ||
      resource.some((item: string) => item.startsWith(key))
    );
  };
  useEffect(() => {
    initEnv();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/gpu/node"
            element={hasResource("/gpu/node") ? <Node /> : <NoAuth />}
          />
          <Route
            path="/gpu/product/gpuInstance"
            element={
              global === globalEnv_global ? (
                <PowerProductManage />
              ) : (
                <PowerProductManageZh />
              )
            }
          />
          <Route
            path="/gpu/product/storage"
            element={<StorageProductManage />}
          />

          <Route
            path="/pricing/product"
            element={
              hasResource("/pricing/product") ? <ProductManager /> : <NoAuth />
            }
          />
          <Route
            path="/pricing/discount"
            element={
              hasResource("/pricing/product") ? <Discount /> : <NoAuth />
            }
          />
          <Route
            path="/pricing/bill"
            element={
              hasResource("/gpu/beacon/customer-trade") ? (
                global === globalEnv_global ? (
                  <CustomerTrade />
                ) : (
                  <CustomerTradeZh />
                )
              ) : (
                <NoAuth />
              )
            }
          />
          <Route
            path="/pricing/customer-bill"
            element={
              hasResource("/pricing/customer-bill") ? (
                <CustomerBill />
              ) : (
                <NoAuth />
              )
            }
          />
          <Route
            path="/pricing/voucher"
            element={
              hasResource("/gpu/beacon/voucher") ? (
                global === globalEnv_global ? (
                  <VoucherEn />
                ) : (
                  <VoucherZh />
                )
              ) : (
                <NoAuth />
              )
            }
          />
          <Route
            path="/pricing/credit"
            element={
              hasResource("/pricing/credit") ? <CreditLimit /> : <NoAuth />
            }
          />
          <Route
            path="/pricing/balance-warning"
            element={
              hasResource("/pricing/balance-warning") ? (
                <BalanceWarning />
              ) : (
                <NoAuth />
              )
            }
          />
          <Route
            path="/meta/productType"
            element={
              hasResource("/meta/productType") ? (
                <ProductTypeManager />
              ) : (
                <NoAuth />
              )
            }
          />
          <Route
            path="/meta/gpuModelManage"
            element={
              hasResource("/meta/gpuModelManage") ? (
                <GpuModelManage />
              ) : (
                <NoAuth />
              )
            }
          />
          <Route
            path="/meta/clusterManage"
            element={
              hasResource("/meta/clusterManage") ? (
                <ClusterManage />
              ) : (
                <NoAuth />
              )
            }
          />
          <Route path="/llm/product" element={<LLMProcut />} />
          <Route path="/serverless/product" element={<ServerlessProcut />} />
          <Route path="/account/profile" element={<Profile />} />
          <Route
            path="/account/ban"
            element={
              hasResource("/account/ban") ? <Ban /> : <NoAuth />
            }
          />
        </Route>
        <Route
          path="/model-api/*"
          element={
            <Layout>
              <SubApp />
            </Layout>
          }
        />
        <Route
          path="/strapi/*"
          element={
            <Layout noPadding>
              <StrapiIframe />
            </Layout>
          }
        ></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
