import { Button, DatePicker, Form, Input, Select, Space, Table } from "antd";
import styles from "./styles.module.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BusinessLineList } from "@/constants";
import { getProductType } from "@/api/pricing";
import { PaginationComp } from "@/components/pagination";
import { ColumnsType } from "antd/es/table";
import dayjs, { Dayjs } from "dayjs";
import { getBillList } from "@/api/beacon";
import Big from "big.js";
import { uniqueId } from "lodash";
import { removeObjectEmptyValues } from "@/utils";
import utc from "dayjs/plugin/utc";
import { getEnv, globalEnv_global } from "@/config";

dayjs.extend(utc);

const { Item } = Form;

const MyItem = ({
  name,
  label,
  children,
}: {
  name?: string;
  label?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Item name={name} label={label} className={styles.form_item}>
      {children}
    </Item>
  );
};

export default function CustomerBill() {
  const [form] = Form.useForm();
  const [selectedBusinessType, setSelectedBusinessType] = useState<string>("");
  const [productCategoryList, setProductCategoryList] = useState<any[]>([]);
  const [pageParams, setPageParams] = useState<any>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState<number>(0);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const categoryOptions = useMemo(() => {
    if (!selectedBusinessType || selectedBusinessType === "") {
      return productCategoryList;
    }
    return productCategoryList.filter(
      (one) => one.businessType === selectedBusinessType
    );
  }, [productCategoryList, selectedBusinessType]);

  const priceFormatter = (v: any) => {
    if (Number.isNaN(Number(v))) {
      return 0;
    }
    if (typeof v === "object") {
      return 0;
    }
    return Big(v).div(10000).toString();
  };

  const fetchData = useCallback((qParams?: any) => {
    const formatTime = (date: Dayjs | undefined) => {
      if (!date) {
        return;
      }
      const { global } = getEnv();
      if (global === globalEnv_global) {
        const localDate = date.format("YYYY-MM-DD HH:mm:ss");
        const utcUnix = dayjs.utc(localDate, "YYYY-MM-DD HH:mm:ss").unix();
        return utcUnix;
      } else {
        return date.unix();
      }
    };
    setLoading(true);
    const params = form.getFieldsValue();
    const [startTime, endTime] = params.chargeType ?? [];
    const queryParams = removeObjectEmptyValues({
      ...pageParams,
      ...params,
      chargeType: undefined,
      startTime: formatTime(startTime),
      endTime: formatTime(endTime),
      ...qParams,
    });
    getBillList(queryParams)
      .then((res) => {
        if (res.total !== undefined && Array.isArray(res.data)) {
          setTotal(res.total);
          setDataSource(
            res.data.map((one: any) => ({
              ...one,
              _id: uniqueId(),
            }))
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageParams, form]);

  const columns: ColumnsType<any> = [
    {
      title: "客户ID",
      dataIndex: "userId",
    },
    {
      title: "账单周期",
      dataIndex: "date",
      render: (_, record: any) => {
        if (record?.startTime && record?.endTime) {
          return (
            dayjs.unix(record?.startTime).format("YYYY-MM-DD HH:mm:ss") +
            " ~ " +
            dayjs.unix(record?.endTime).format("YYYY-MM-DD HH:mm:ss")
          );
        }
      },
    },
    {
      title: "产品名称",
      dataIndex: "productName",
    },
    {
      title: "资源ID",
      dataIndex: "ownerId",
    },
    {
      title: "应付金额",
      dataIndex: "amount",
      render(_, record) {
        return priceFormatter(record?.amount);
      },
    },
    {
      title: "优惠券抵扣",
      dataIndex: "voucherAmount",
      render(_, record) {
        return priceFormatter(record?.voucherAmount);
      },
    },
    {
      title: "余额支付",
      dataIndex: "payAmount",
      render(_, record) {
        return priceFormatter(record?.payAmount);
      },
    },
  ];

  useEffect(() => {
    setSelectedBusinessType("gpu_instance");
    form.setFieldsValue({
      businessType: "gpu_instance",
      chargeType: [
        dayjs().subtract(1, "week").startOf("day"),
        dayjs().endOf("day"),
      ],
    });
  }, [form]);

  useEffect(() => {
    getProductType({}).then((res) => {
      if (Array.isArray(res.data)) {
        setProductCategoryList(
          res.data.map((one: any) => ({
            category: one.category,
            businessType: one.businessType,
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <div className={styles.search_container}>
        <Form layout="inline" form={form}>
          <MyItem name="userId" label="客户ID">
            <Input placeholder="uuid/手机号/邮箱" />
          </MyItem>
          <MyItem name="productName" label="产品名称">
            <Input placeholder="产品名称" />
          </MyItem>
          <MyItem name="businessType" label="业务线">
            <Select
              style={{ width: 160 }}
              options={BusinessLineList}
              onChange={(value) => {
                setSelectedBusinessType(value);
              }}
              allowClear
              placeholder="请选择业务线"
            />
          </MyItem>
          <MyItem label="产品分类" name="productCategory">
            <Select
              style={{ width: 180 }}
              options={categoryOptions.map((one) => ({
                label: one.category,
                value: one.category,
              }))}
              allowClear
              placeholder="请选择产品分类"
            />
          </MyItem>
          <MyItem label="资源ID" name="ownerId">
            <Input placeholder="资源ID" />
          </MyItem>
          <MyItem label="查询时期" name="chargeType">
            <DatePicker.RangePicker />
          </MyItem>
          <MyItem>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  fetchData({
                    pageIndex: 1,
                    pageSize: 10,
                  });
                  setPageParams({ pageIndex: 1, pageSize: 10 });
                }}
                loading={loading}
              >
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields();
                  form.setFieldsValue({
                    businessType: "gpu_instance",
                    chargeType: [
                      dayjs().subtract(1, "week").startOf("day"),
                      dayjs().endOf("day"),
                    ],
                  });
                  setPageParams({ pageIndex: 1, pageSize: 10 });
                }}
              >
                重置
              </Button>
            </Space>
          </MyItem>
        </Form>
      </div>
      <div className={styles.table_container}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowKey="_id"
          loading={loading}
        />
      </div>
      <div className={styles.pagination}>
        <PaginationComp
          total={total}
          pageNum={pageParams.pageIndex}
          pageSize={pageParams.pageSize}
          onChange={(pageIndex, pageSize) => {
            setPageParams({ pageIndex, pageSize });
          }}
        />
      </div>
    </div>
  );
}
