import { PaginationComp } from "@/components/pagination";
import styles from "./index.module.scss";
import { Form, Input, Button, Table, Modal, Tooltip, message } from "antd";
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { getBillingTypeName, handleShowResource } from "@/utils";
import { ColumnsType } from "antd/es/table";
import { deleteNode, getNodeList, setNodesLabels } from "@/api/node";
import PodState from "./PodState";
import EditLabels from "./editLabels";
import { getEnv, globalEnv_zh } from "@/config";
const { global } = getEnv();
const isZh = global === globalEnv_zh;

const { Item } = Form;

const getSubValue = (record: any, field: string, subField: string) => {
  console.log("getSubValue record:", record);
  console.log("getSubValue field:", field);
  console.log("getSubValue subField:", subField);
  // console.log("getSubValue record[field][subField]:", record?[field]?[subField]);
  return record && record[field] ? record[field][subField] : "";
};

const errorObj: any = {
  offline: "节点离线",
  "node-docker": "docker错误",
  "node-system": "系统错误",
  "node-nvml": "nvml错误",
  "node-unknown": "其他未知错误",
};

function getStateErrText(state: any): string {
  let retText = "";
  switch (state) {
    case "offline":
      retText = "实例离线";
      break;
    case "node-insufficient-gpu":
      retText = "GPU资源不足";
      break;
    case "node-insufficient-cpu":
      retText = "CPU资源不足";
      break;
    case "node-insufficient-memory":
      retText = "内存资源不足";
      break;
    case "node-insufficient-rootfs":
      retText = "系统存储资源不足";
      break;
    case "node-insufficient-localstorage":
      retText = "本地存储资源不足";
      break;
    case "node-docker-pull-image-error":
      retText = "镜像拉取失败";
      break;
    default:
      retText = "";
      break;
  }
  return retText;
}

export default function Node() {
  const [modalInfo, setModalInfo] = useState({
    showModal: false,
    nodeId: "",
    labels: [],
  });
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const columns: ColumnsType<any> = [
    {
      title: "基础信息",
      width: "100px",
      align: "center",
      key: "baseInfo",
      render: (_: any, record: any) => {
        return record.baseInfo;
      },
      children: [
        {
          title: "设备sn号",
          width: "100px",
          align: "center",
          key: "baseInfo.snNumber",
          render: (_: any, record: any) =>
            getSubValue(record, "baseInfo", "snNumber"),
        },
        {
          title: "设备激活码",
          width: "100px",
          align: "center",
          sorter: true,
          key: "baseInfo.activationCode",
          render: (_: any, record: any) =>
            getSubValue(record, "baseInfo", "activationCode"),
        },
        {
          title: "激活时间",
          width: "100px",
          align: "center",
          sorter: true,
          key: "baseInfo.activationTime",
          render: (_: any, record: any) => {
            const activationTime = getSubValue(
              record,
              "baseInfo",
              "activationTime"
            );
            return (
              <div>
                {activationTime && !isNaN(Number(activationTime))
                  ? dayjs.unix(activationTime).format("YYYY-MM-DD HH:mm:ss")
                  : ""}
              </div>
            );
          },
        },
        {
          title: "所属集群",
          width: "100px",
          align: "center",
          key: "baseInfo.cluster",
          render: (_: any, record: any) =>
            getSubValue(record, "baseInfo", "cluster"),
        },
        {
          title: "支持CUDA版本",
          width: "100px",
          align: "center",
          key: "baseInfo.cudaVersion",
          render: (_: any, record: any) =>
            getSubValue(record, "baseInfo", "cudaVersion"),
        },
        {
          title: "在线状态",
          width: "100px",
          align: "center",
          key: "baseInfo.onlineStatus",
          render: (_: any, record: any) => {
            const onlineStatus = getSubValue(
              record,
              "baseInfo",
              "onlineStatus"
            );
            const errorStatusObj = getSubValue(
              record,
              "baseInfo",
              "nodeErrorStatus"
            );
            let errorState = "";
            let toolTip = null;
            if (errorStatusObj) {
              errorState = errorStatusObj?.state || "";
              if (errorState) {
                const errorText = errorState
                  ? errorObj[errorState] || "其他未知错误"
                  : "";
                const message = errorStatusObj?.message || "";
                toolTip = (
                  <Tooltip
                    title={
                      <div>
                        <div>{errorText}</div>
                        <div>{message}</div>
                      </div>
                    }
                  >
                    <span style={{ marginLeft: "5px", color: "red" }}>
                      <QuestionCircleOutlined />
                    </span>
                  </Tooltip>
                );
              }
            }
            if (onlineStatus === "online") {
              return <div style={{ color: "green" }}>在线{toolTip}</div>;
            } else if (onlineStatus === "offline") {
              return <div style={{ color: "red" }}>离线</div>;
            } else if (onlineStatus === "running") {
              return <div style={{ color: "green" }}>运行中{toolTip}</div>;
            } else {
              return (
                <div>
                  {onlineStatus}
                  {toolTip}
                </div>
              );
            }
          },
        },
        {
          title: "云服务类型",
          width: "100px",
          align: "center",
          key: "baseInfo.cloudServiceType",
          render: (_: any, record: any) => {
            const ret = getSubValue(record, "baseInfo", "cloudServiceType");
            if (ret === "Center") {
              return "中心云";
            } else if (ret === "Shared") {
              return "共享云";
            }
          },
        },
      ],
    },
    {
      title: "产品信息",
      children: [
        {
          title: "产品名称",
          width: "100px",
          align: "center",
          key: "productInfo.productName",
          render: (_: any, record: any) =>
            getSubValue(record, "productInfo", "productName"),
        },
        {
          title: "产品规格",
          sorter: true,
          width: "100px",
          align: "center",
          key: "productInfo.productSpec",
          render: (_: any, record: any) =>
            getSubValue(record, "productInfo", "productSpec"),
        },
        {
          title: "上架状态",
          width: "100px",
          align: "center",
          sorter: true,
          key: "productInfo.shelveStatus",
          render: (_: any, record: any) => {
            const shelveStatus = getSubValue(
              record,
              "productInfo",
              "shelveStatus"
            );
            if (shelveStatus) {
              return <div>已上架</div>;
            } else {
              return <div>未上架</div>;
            }
          },
        },
      ],
    },
    {
      title: "硬件信息",
      children: [
        // {
        //   title: '显卡型号',
        //   width: "100px",
        //   align: 'center',
        //   key: 'hardwareInfo.gpuModel',
        //   render: (text: any, record: any, index: any) => getSubValue(record, "hardwareInfo", "gpuModel")
        // },
        // {
        //   title: '显存GB',
        //   width: "100px",
        //   align: 'center',
        //   key: 'hardwareInfo.gpuSizeGB',
        //   render: (text: any, record: any, index: any) => getSubValue(record, "hardwareInfo", "gpuSizeGB")
        // },
        {
          title: "可用显卡数",
          width: "100px",
          align: "center",
          key: "hardwareInfo.gpuAvailableNumber",
          render: (_: any, record: any) =>
            getSubValue(record, "hardwareInfo", "gpuAvailableNumber"),
        },
        {
          title: "总显卡数",
          width: "100px",
          align: "center",
          key: "hardwareInfo.gpuAmount",
          render: (_: any, record: any) =>
            getSubValue(record, "hardwareInfo", "gpuAmount"),
        },
        {
          title: "CPU型号",
          width: "100px",
          align: "center",
          key: "hardwareInfo.cpuModel",
          render: (_: any, record: any) =>
            getSubValue(record, "hardwareInfo", "cpuModel"),
        },
        {
          title: "可用CPU核数",
          width: "100px",
          align: "center",
          key: "hardwareInfo.cpuAvailableCoreNumber",
          render: (_: any, record: any) =>
            getSubValue(record, "hardwareInfo", "cpuAvailableCoreNumber"),
        },
        {
          title: "总CPU核数",
          width: "100px",
          align: "center",
          key: "hardwareInfo.cpuCoreNumber",
          render: (_: any, record: any) =>
            getSubValue(record, "hardwareInfo", "cpuCoreNumber"),
        },
        {
          title: "内存容量GB",
          width: "100px",
          align: "center",
          key: "hardwareInfo.memorySizeGB",
          render: (_: any, record: any) =>
            getSubValue(record, "hardwareInfo", "memorySizeGB"),
        },
        {
          title: "硬盘总容量TB",
          width: "100px",
          align: "center",
          key: "hardwareInfo.hardDiskTotalSizeTB",
          render: (_: any, record: any) => {
            const sumOrigin = getSubValue(
              record,
              "hardwareInfo",
              "hardDiskTotalSizeTB"
            );
            const sum = Number(sumOrigin);
            if (!isNaN(sum) && sumOrigin !== "") {
              return (
                <div>{(Math.round((sum / 1024) * 100) / 100).toFixed(2)}</div>
              );
            } else {
              return "";
            }
          },
        },
        // {
        //   title: '硬盘详情',
        //   width: "200px",
        //   align: 'center',
        //   key: 'hardwareInfo.hardDiskDetail',
        //   render: (text: any, record: any, index: any) => getHardWareValue(record, "hardwareInfo", "hardDiskDetail")
        // },
      ],
    },
    {
      title: "标记信息",
      width: "100px",
      align: "center",
      render: (_: any, record: any) => {
        return <>{(record.labels || []).map((item: any) => <>
          <div>
            key: {item.key}
          </div>
          <div>
            value: {item.value.indexOf("private-") === 0 ?
            (!item.isMember ? ("常规用户/private-" + (isZh ? item.phone : item.email)) : "子账号/" + item.value) : item.value}
          </div>
          <div>
            endTime: {item.endTime && Number(item.endTime) ? 
            dayjs.unix(Number(item.endTime)).format("YYYY-MM-DD HH:mm:ss"): "永久生效"}
          </div><br/>
        </>)}</>;
      },
    },
    {
      title: "操作",
      width: "100px",
      align: "center",
      render: (_: any, record: any) => {
          return (
            <div>
            {
              handleShowResource("node-set-labels") && 
                <Button
                  style={{marginRight: "10px"}}
                  onClick={() => {
                    setModalInfo({showModal: true, nodeId: record?.baseInfo?.snNumber, labels: record?.labels || []})
                  }}
                  type="primary"
                  ghost
                >
                  设置标记
                </Button>
              }
              {
                handleShowResource("node-del") && 
                <Button
                  onClick={() => {
                    Modal.confirm({
                      width: "460px",
                      title: "删除节点",
                      icon: <ExclamationCircleOutlined />,
                      content: (
                        <>
                          <span style={{ color: "#303133" }}>
                            确认删除当前节点（{record?.baseInfo?.snNumber}）？
                          </span>
                        </>
                      ),
                      okText: "删除",
                      cancelText: "取消",
                      onOk: () => {
                        deleteNode({ nodeId: record?.baseInfo?.snNumber }).then(
                          (res: any) => {
                            console.log("reqSendReceipt res: ", res);
                            message.success("删除成功");
                            // 删除后刷新列表
                            fetchData();
                          }
                        );
                      },
                    });
                  }}
                  type="primary"
                  danger
                  ghost
                >
                  删除
                </Button>
              }
            </div>
          );
      },
    },
  ];
  function finishOperate(cancelMark: boolean,
    nodeId: string,
    labels: any) {
      if (!cancelMark) {
        setModalInfo({ ...modalInfo, showModal: false });
      } else {
        setSaveBtnLoading(true);
        const labelsTmp = [];
        for (let i = 0;i<labels.length;i++) {
          const item = {...labels[i]};
          const endTime = item.endTime;
          if (endTime) {
            item.endTime = dayjs(
              endTime.format("YYYY-MM-DD HH:mm:ss")
            )
              .unix()
          } else {
            item.endTime = undefined;
          }
          if (item.valueType === "public") {
            item.value = item.valueType;
          } else if (item.valueType === "private") {
            item.value = item.valueType + "-" + (item.isMember ? item.memberId : item.uuid);
          } else {
            item.value = "";
          }
          item.valueType = undefined;
          item.uuid = undefined;
          labelsTmp.push(item);
        }
        
        setNodesLabels({
            nodeLabels: [{
            nodeId,
            labels: labelsTmp
          }]})
          .then(() => {
            message.success("操作成功");
            setModalInfo({ ...modalInfo, showModal: false });
            fetchData();
          })
          .finally(() => {
            setSaveBtnLoading(false);
          });
      }
  }

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [pageParams, setPageParams] = useState({
    pageSize: 10,
    pageNum: 1,
  });
  const [params, setParams] = useState({
    nodeId: "",
    clusterId: "",
    specId: "",
  });

  const fetchData = useCallback((queryParams?: any) => {
    const query = {
      ...params,
      pageSize: pageParams.pageSize,
      page: pageParams.pageNum,
      ...queryParams,
    };
    setLoading(true);
    getNodeList(query)
      .then((res: any) => {
        setTotal(res?.total || 0);
        setDataSource(res?.data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params, pageParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // 重置页面
  useEffect(() => {
    setPageParams({
      pageSize: 10,
      pageNum: 1,
    });
  }, [params]);

  return (
    <div>
      <div className={styles.search_container}>
        <Form layout="inline">
          <Item label="节点ID">
            <Input
              value={params.nodeId}
              onChange={(e) => setParams({ ...params, nodeId: e.target.value })}
            />
          </Item>
          <Item label="集群ID">
            <Input
              value={params.clusterId}
              onChange={(e) =>
                setParams({ ...params, clusterId: e.target.value })
              }
            />
          </Item>
          <Item label="规格ID">
            <Input
              value={params.specId}
              onChange={(e) => setParams({ ...params, specId: e.target.value })}
            />
          </Item>
          <Item>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                fetchData({ page: 1, pageSize: 10 });
                setPageParams({ pageNum: 1, pageSize: 10 });
              }}
            >
              开始查询
            </Button>
          </Item>
        </Form>
      </div>
      <div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 3300 }}
          bordered
          pagination={false}
          rowKey={(record: any) => record.baseInfo.snNumber}
          expandable={{
            expandRowByClick: true,
            expandedRowRender: (record: any) => (
              <Table
                pagination={false}
                columns={[
                  {
                    title: "实例id",
                    width: "100px",
                    align: "center",
                    key: "instanceDetail.instanceId",
                    render: (_: any, record: any) => {
                      return <div>{record.instanceId}</div>;
                    },
                  },
                  {
                    title: "实例状态",
                    width: "100px",
                    align: "center",
                    key: "instanceDetail.instanceStatus",
                    render: (_: any, record: any) => {
                      const stateTxt = getStateErrText(
                        record?.instanceErrorStatus?.state
                      );
                      const errorMessage =
                        record?.instanceErrorStatus?.message || "";
                      return (
                        <>
                          <PodState
                            state={record.instanceStatus}
                            errorText={stateTxt}
                            errorMessage={errorMessage}
                          ></PodState>
                          {/* {
                      stateTxt ? <span style={{display: "block", color: "red"}}>{stateTxt}</span> : ""
                    } */}
                        </>
                      );
                    },
                  },
                  {
                    title: "实例规格",
                    width: "100px",
                    align: "center",
                    key: "instanceDetail.instanceSpec",
                    render: (_: any, record: any) => {
                      return <div>{record.instanceSpec}</div>;
                    },
                  },
                  {
                    title: "访问ip",
                    width: "100px",
                    align: "center",
                    key: "instanceDetail.visitIp",
                    render: (_: any, record: any) => {
                      return <div>{record.visitIp}</div>;
                    },
                  },
                  {
                    title: "存储大小",
                    width: "100px",
                    align: "center",
                    key: "instanceDetail.storageSize",
                    render: (_: any, record: any) => {
                      return <div>{record.storageSize} GB</div>;
                    },
                  },
                  {
                    title: "计费模式",
                    width: "100px",
                    align: "center",
                    key: "instanceDetail.chargingModel",
                    render: (_: any, record: any) => {
                      return (
                        <div>
                          {getBillingTypeName(
                            ((record || {}) as any).chargingModel
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    title: "价格",
                    width: "100px",
                    align: "center",
                    key: "instanceDetail.instancePrice",
                    render: (_: any, record: any) => {
                      return (
                        <div>
                          {record.instancePrice || record.instancePrice === 0
                            ? Math.round(
                                (Number(record.instancePrice) / 100) * 100
                              ) / 100
                            : ""}
                        </div>
                      );
                    },
                  },
                  {
                    title: "创建时间",
                    width: "100px",
                    align: "center",
                    key: "instanceDetail.createTime",
                    render: (_: any, record: any) => {
                      return (
                        <div>
                          {record?.createTime &&
                          !isNaN(Number(record.createTime)) &&
                          Number(record.createTime)
                            ? dayjs
                                .unix(record.createTime)
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "/"}
                        </div>
                      );
                    },
                  },
                  {
                    title: "到期时间",
                    width: "100px",
                    align: "center",
                    key: "instanceDetail.expirationTime",
                    render: (_: any, record: any) => {
                      return (
                        <div>
                          {record?.expirationTime &&
                          !isNaN(Number(record.expirationTime)) &&
                          Number(record.expirationTime)
                            ? dayjs
                                .unix(record.expirationTime)
                                .format("YYYY-MM-DD HH:mm:ss")
                            : "/"}
                        </div>
                      );
                    },
                  },
                ]}
                dataSource={record.instanceDetail}
              />
            ),
          }}
        />
      </div>
      <div className={styles.pagination_container}>
        <PaginationComp
          total={total}
          pageSize={pageParams.pageSize}
          pageNum={pageParams.pageNum}
          onChange={(pageNum, pageSize) => setPageParams({ pageNum, pageSize })}
        />
      </div>
      {
        modalInfo.showModal && 
        <EditLabels
          nodeId={modalInfo.nodeId}
          btnLoading={saveBtnLoading}
          finishOperate={finishOperate}
          showModal={modalInfo.showModal}
          labelList={modalInfo.labels}
        />
      }
    </div>
  );
}
