import { request } from "./fetch";

export function getNodeList(params: any) {
  return request({
    url: "/api/v1/admin/node/list",
    method: "GET",
    query: params,
  });
}

export function deleteNode(params: any) {
  return request({
    url: "/api/v1/admin/node/delete",
    method: "POST",
    data: params,
  });
}

export function setNodesLabels(params: any) {
  return request({
    url: "/api/v1/admin/node/set/labels",
    method: "POST",
    data: params,
  });
}