import { Button, Form, Input, message, Modal, Space, Table } from "antd";
import styles from "./index.module.scss";
import { useCallback, useEffect, useState } from "react";
import { banUser, queryBanUser } from "@/api/user";
import { removeObjectEmptyValues } from "@/utils";
import { PaginationComp } from "@/components/pagination";
import { BAN_USER } from "@/constants/auth";
import { hasResource } from "@/constants/auth";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

export default function Ban() {
  const { resource } = useSelector((state: RootState) => state.user);
  const [form] = Form.useForm();
  const [banForm] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [banLoading, setBanLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleSearch = useCallback((params: any) => {
    setLoading(true);
    queryBanUser(params)
      .then((res) => {
        setDataSource(res.data);
        setTotal(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  
  const handleBan = (params: any) => {
    setBanLoading(true);
    banUser(params)
      .then(() => {
        message.success("封禁成功");
        setModalVisible(false);
        handleSearch({
        page: 1,
        pageSize: 10,
      });
    })
    .finally(() => {
      setBanLoading(false);
    });
  };

  const columns = [
    {
      title: "uuid",
      dataIndex: "uuid",
    },
    {
      title: "手机号",
      dataIndex: "phone",
    },
    {
      title: "邮箱",
      dataIndex: "email",
    },
    {
      title: "封禁原因",
      dataIndex: "banReason",
    },
  ];

  useEffect(() => {
    const formData = form.getFieldsValue();
    handleSearch(
      removeObjectEmptyValues({
        page: pagination.current,
        pageSize: pagination.pageSize,
        ...formData,
      })
    );
  }, [pagination, form, handleSearch]);
  
  const handleReset = () => {
    form.resetFields();
    setPagination({
      current: 1,
      pageSize: 10,
    });
    handleSearch({
      page: 1,
      pageSize: 10,
    });
  };

  useEffect(() => {
    if(modalVisible){
      banForm.resetFields();
    }
  }, [modalVisible, banForm]);

  return (
    <div>
      <div className={styles.search_form}>
        <Form form={form} layout="inline">
          <Form.Item label="uuid" name="uuid">
            <Input />
          </Form.Item>
          <Form.Item label="手机号" name="phone">
            <Input />
          </Form.Item>
          <Form.Item label="邮箱" name="email">
            <Input />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  const formData = form.getFieldsValue();
                  setPagination({
                    current: 1,
                    pageSize: 10,
                  });
                  handleSearch(
                    removeObjectEmptyValues({
                      page: 1,
                      pageSize: 10,
                      ...formData,
                    })
                  );
                }}
              >
                搜索
              </Button>
              <Button onClick={handleReset}>重置</Button>
              {hasResource(resource, BAN_USER) && (
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setModalVisible(true);
                  }}
              >
                  封禁
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </div>
      <div className={styles.table_container}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
          rowKey="uuid"
        />
      </div>
      <div className={styles.pagination_container}>
        <PaginationComp
          pageNum={pagination.current}
          pageSize={pagination.pageSize}
          total={total}
          onChange={(pageNum, pageSize) => {
            setPagination({
              current: pageNum,
              pageSize,
            });
          }}
        />
      </div>
      <Modal
        title="封禁用户"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          handleBan(banForm.getFieldsValue());
        }}
        confirmLoading={banLoading}
      >
        <Form form={banForm} layout="vertical">
          <Form.Item label="账户" name="account">
            <Input placeholder="uuid/邮箱/手机号" />
          </Form.Item>
          <Form.Item label="封禁原因" name="banReason">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
