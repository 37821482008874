import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import { queryAccountWithoutCatchErr } from "@/api/user";
import dayjs from "dayjs";
import { getEnv, globalEnv_zh } from "@/config";
const { global } = getEnv();
const isZh = global === globalEnv_zh;
// `
export default function EditLabels({
  showModal = false,
  nodeId,
  labelList = [],
  finishOperate,
  btnLoading
}: {
  showModal: boolean;
  nodeId: string,
  labelList: Array<any>;
  finishOperate: (
    cancelMark: boolean,
    type: string,
    powerPriceForm: any
  ) => void;
  btnLoading: boolean;
}) {
  const [labels, setLabels] = useState<Array<any>>([]);
  useEffect(() => {
    const labelsTmp = [];
    for (let i = 0;i<labelList.length;i++) {
      const item = JSON.parse(JSON.stringify(labelList[i]));
      const endTimeTmp = item.endTime;
      if (endTimeTmp && Number(endTimeTmp) > 0)  {
        item.endTime = dayjs.unix(Number(endTimeTmp))
      } else {
        item.endTime = "";
      }
      const valueTmp = item.value;
      if (valueTmp.indexOf("public") === 0) {
        item.valueType = "public";
        item.uuid = "";
        item.memberId = "";
      } else if (valueTmp.indexOf("private-") === 0) {
        item.valueType = "private";
        if (item.isMember) {
          item.memberId = valueTmp.substring(8);
        } else {
          item.uuid = valueTmp.substring(8);
        }
      } else {
        item.valueType = "";
        item.uuid = "";
        item.memberId = "";
      }
      if (valueTmp.indexOf("private-") === 0 &&!item.isMember) {
        item.uulist = [
          {
            uuid: item.uuid,
            email: item.email,
            phone: item.phone,
          }
        ];
      } else {
        item.uulist = [];
      }
      labelsTmp.push(item);
    }
    setLabels(labelsTmp);
  }, [labelList])
  function cancelOperate() {
    finishOperate(false, "", []);
  }
  function operateData() {
    if (labels) {
      const instanceLabels = labels.filter((item: any) => item.key === "instance.pool");
      if (instanceLabels && instanceLabels.length >= 2) {
        message.error("key为instance.pool的设置不能超过1项");
        return;
      }
      const serverlessLabels = labels.filter((item: any) => item.key === "serverless.pool");
      if (serverlessLabels && serverlessLabels.length >= 2) {
        message.error("serverless.pool的设置不能超过1项");
        return;
      }
      for(let i=0;i<labels.length;i++) {
        if (!(labels[i].key)) {
          message.error("key为必填项");
          return;
        }
        if (labels[i].valueType === "private") {
          if (labels[i].isMember) {
            if (!(labels[i].memberId) || !(labels[i].memberId.trim())) {
              message.error("value为private时，member模式下，memberId不能为空");
              return;
            }
          } else {
            if (!(labels[i].uuid) || !(labels[i].uuid.trim())) {
              const mode = isZh ? "手机号" : "邮箱";
              message.error("value为private时，" + mode + "模式下，" + mode + "不能为空");
              return;
            }
          }
        }
      }
    }
    finishOperate(true, nodeId, labels || []);
  }
  function addLabel() {
    setLabels([...labels, {key: "", value: "", valueType: "public", uuid: "", endTime: undefined, isMember: false, memberId: "", uulist: []}]);
  }
  function removeCurrentLabel(index: number) {
    const labelsTmp = [...labels];
    labelsTmp.splice(index, 1);
    setLabels(labelsTmp);
  }
  let timeout: ReturnType<typeof setTimeout> | null;
  let currentValue: string;
  
  const fetch = (value: string, callback: (data: any, index: number) => void, index: number) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
  
    const fake = () => {
      queryAccountWithoutCatchErr(value).then((res: any) => {
        console.log("queryAccountWithoutCatchErr res: ", res);
        if (currentValue === value) {
          callback(res, index);
        }
      })
    };
    if (value.length > 0) {
      timeout = setTimeout(fake, 300);
    } else {
      callback(null, index);
    }
  };
  function dealData(data: any, index: number) {
    if (!data) {
      return;
    }
    if (labels[index].uulist.map((ele: any) => ele.uuid).includes(data.uuid)) {
      return;
    } else {
      const newLabels = [...labels];
      newLabels[index].uulist = [...newLabels[index].uulist, data];
      setLabels(newLabels);
    }
  }
  const handleSearch = (newValue: string, index: number) => {
    fetch(newValue, dealData, index);
  };
  const handleChangeTmp = (newValue: string, index: number) => {
    const newLabels = [...labels];
    newLabels[index].uuid = newValue;
    setLabels(newLabels);
  };
  return (
    <>
      <Modal
        open={showModal}
        title="设置节点标记"
        width={"744px"}
        onCancel={cancelOperate}
        footer={[
          <Button onClick={cancelOperate}>取消</Button>,
          <Button onClick={operateData} loading={btnLoading} type="primary">
            确定
          </Button>,
        ]}
      >
        <div>
          <Form className="mr-10" label-position="right" labelCol={{ span: 5 }}>
            <Alert type="warning" message={
              <div>
                <div>1、value 设置个人账户 uuid，支持向个人账户定向调度</div>
                <div>2、value 设置个人账户 owner uuid，支持团队账户所有人定向调度</div>
                <div>3、value 设置个人账户 member id，支持团队账户个人定向调度</div>
                <div>4、member id 调度优先级高于 owner uuid</div>
              </div>
            } />
            <br />
              <div style={{ lineHeight: "30px", gap: "15px", display: "flex", flexDirection: "column" }}>
                  {
                    labels.map((item: any, index: number) => {
                      return <div key={index} style={{ paddingLeft: "20px", border: "1px solid lightgray", borderRadius: "8px" }}>
                      <div style={{ lineHeight: "45px" }}>
                        <label>key：</label>
                        <Select
                          style={{ width: "524px", marginLeft: "32px" }}
                          onChange={(value: any) => {
                            const labelsTmp = [...labels];
                            labelsTmp[index].key = value;
                            if (value === "serverless.pool") {
                              labelsTmp[index].valueType = "public";
                            }
                            setLabels(labelsTmp);
                          }}
                          value={item.key}
                          options={[
                            {value: "instance.pool", label: "instance.pool"},
                            {value: "serverless.pool", label: "serverless.pool"},
                          ]}
                        />
                      </div>
                      <div style={{ lineHeight: "45px" }}>
                        <label>value:</label>
                        {/* <Input 
                          style={{ width: "100px" }}
                          value={item.value}
                          onChange={(e: any) => {
                            const labelsTmp = [...labels];
                            labelsTmp[index].value = e.target.value;
                            setLabels(labelsTmp); 
                          }}
                          /> */}
                          <Select
                            disabled={item.key === "serverless.pool"}
                            style={{ width: "100px", marginLeft: "32px", marginRight: "20px" }}
                            onChange={(value: any) => {
                              const labelsTmp = [...labels];
                              labelsTmp[index].valueType = value;
                              setLabels(labelsTmp);
                            }}
                            value={item.valueType}
                            options={[
                              {value: "public", label: "public"},
                              {value: "private", label: "private"},
                            ]}
                          />
                          {
                            item.valueType === "private" && <>
                            {/* <Checkbox checked={item.isMember} onChange={(e: any) => {
                              const labelsTmp = [...labels];
                              labelsTmp[index].isMember = e.target.checked;
                              setLabels(labelsTmp);
                            }} >是member</Checkbox> */}
                            <Select
                              style={{ width: "100px", marginLeft: "-6px", marginRight: "2px" }}
                              onChange={(value: any) => {
                                console.log("value: ", value);
                                const labelsTmp = [...labels];
                                labelsTmp[index].isMember = value;
                                setLabels(labelsTmp);
                              }}
                              value={item.isMember}
                              options={[
                                {value: false, label: isZh ? "手机号" : "邮箱"},
                                {value: true, label: "memberId"},
                              ]}
                            />
                            <label>{item.isMember ? "memberId:" : "uuid:"}</label>
                            {
                            item.isMember ? <Input 
                              style={{ width: "230px", marginLeft: "10px" }}
                              value={item.memberId}
                              onChange={(e: any) => {
                                const labelsTmp = [...labels];
                                labelsTmp[index].memberId = e.target.value;
                                setLabels(labelsTmp); 
                              }}
                              /> :
                              <Select
                                // mode="multiple"
                                style={{ width: "266px", marginLeft: "10px" }}
                                showSearch
                                value={item.uuid}
                                defaultActiveFirstOption={false}
                                suffixIcon={null}
                                filterOption={false}
                                onSearch={(value: any) => handleSearch(value, index)}
                                onChange={(value: any) => handleChangeTmp(value, index)}
                                notFoundContent={null}
                                options={(item.uulist || []).map((d: any) => ({
                                  value: d.uuid,
                                  label: isZh ? d.phone : d.email
                                }))}
                              />
                            }
                          </>
                        }
                      </div>
                      <div style={{ lineHeight: "45px" }}>
                        <label>endTime：</label>
                        <DatePicker
                          showTime
                          value={item.endTime}
                          style={{ width: "524px", marginRight: "10px" }}
                          onChange={(value: any) => {
                            const labelsTmp = [...labels];
                            labelsTmp[index].endTime = value;
                            setLabels(labelsTmp); 
                          }}
                        />
                        <Button onClick={() => removeCurrentLabel(index)} type="primary" danger>删除</Button>
                      </div>
                    </div>
                    })
                  }
              </div>
            <Form.Item required>
              <Button style={{marginTop: "10px", float: "right"}}
                onClick={addLabel}
              >
                新增
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
