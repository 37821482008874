import { getUserAvgCost, UserBalanceMonitor } from "@/api/pricing";
import { Feishu_Uid_Map } from "@/constants/feishu";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Divider,
  Select,
  Switch,
  Radio,
  Button,
  message,
  Space,
  Spin,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import styles from "./style.module.scss";
import { RedoOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { removeObjectEmptyValues } from "@/utils";
import pinyin from "pinyin";
import { nanoid } from "nanoid";

type Rule = {
  threshold?: number;
  enable: boolean;
  way: number; // 1: 按余额, 2: 预计可用天数
  lastDays?: number | undefined; // 可用天数, 当way为2时，必填
  uniqueId: string;
};

const filterOption = (input: string, option: any) => {
  const value = option?.label.toLowerCase();
  const inputLower = input.toLowerCase();

  // Convert the value to pinyin
  const pinyinValue = pinyin(value, { style: pinyin.STYLE_NORMAL })
    .flat()
    .join("");

  // Check if the input matches the value or its pinyin
  return value.includes(inputLower) || pinyinValue.includes(inputLower);
};

const defaultRule: Rule = {
  threshold: 0,
  enable: true,
  way: 1,
  lastDays: undefined,
  uniqueId: nanoid(),
};

export function ConfigModal({
  open,
  onCancel,
  type,
  onConfirm,
  isZH,
  loading,
  editData,
}: {
  open: boolean;
  onCancel: () => void;
  type: "add" | "edit";
  onConfirm: (values: any) => void;
  loading: boolean;
  isZH: boolean;
  editData: UserBalanceMonitor | Record<string, any>;
}) {
  const [form] = Form.useForm();
  const [rules, setRules] = useState<Rule[]>([defaultRule]);
  const [editRule, setEditRule] = useState<
    Rule & {
      isEdit: boolean;
      type: "add" | "edit";
    }
  >({
    ...defaultRule,
    isEdit: false,
    type: "add",
  });
  const [avgCost, setAvgCost] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getAvgCost = useCallback(
    debounce(async (lastDays: number) => {
      try {
        const formData = form.getFieldsValue();
        if (!formData.uuid && !formData.email && !formData.phone) {
          message.info("请输入用户信息");
          return;
        }
        setIsLoading(true);
        const res = await getUserAvgCost(
          removeObjectEmptyValues({
            uuid: formData.uuid,
            email: formData.email,
            phone: formData.phone,
            lastDays: lastDays,
          })
        );
        if (res?.amount) {
          setAvgCost(res.amount);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [form]
  );

  useEffect(() => {
    if (type === "edit") {
      form.setFieldsValue(editData);
      if (Array.isArray(editData.rules)) {
        setRules(
          editData.rules.map((r) => ({
            ...r,
            uniqueId: nanoid(),
            threshold: Number(r.threshold),
          }))
        );
      }
    } else {
      form.resetFields();
      setRules([]);
      setEditRule({
        ...defaultRule,
        isEdit: false,
        type: "add",
      });
    }
  }, [type, editData, form]);

  useEffect(() => {
    if (editRule.isEdit && editRule.lastDays) {
      getAvgCost(editRule.lastDays);
    }
  }, [editRule.isEdit, editRule.lastDays, getAvgCost]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setRules([]);
      setEditRule({
        ...defaultRule,
        isEdit: false,
        type: "add",
      });
    }
  }, [open, form]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={type === "add" ? "添加" : "编辑"}
      confirmLoading={loading}
      onOk={() => {
        if (rules.length === 0) {
          message.info("请先添加告警规则");
          return;
        }
        // 校验每种告警方式只能添加一条
        const wayMap = new Map();
        rules.forEach((r) => {
          wayMap.set(r.way, r);
        });
        if (wayMap.size !== rules.length) {
          message.error("每种告警方式只能添加一条");
          return;
        }
        form.validateFields().then((values) => {
          onConfirm({
            ...values,
            rules: rules
              .map((r) => ({
                way: r.way,
                threshold: r.threshold ?? 0,
                lastDays: r.lastDays ?? 0,
                enable: r.enable,
              }))
              .sort((a, b) => a.way - b.way),
          });
        });
      }}
    >
      <Form layout="vertical" form={form}>
        <div style={{ marginBottom: "20px", fontWeight: 500 }}>
          下面任选其一即可
        </div>
        {isZH ? (
          <>
            <Form.Item style={{ marginBottom: "4px" }} label="UUID" name="uuid">
              <Input />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "4px" }}
              label="手机号"
              name="phone"
            >
              <Input />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item style={{ marginBottom: "4px" }} label="UUID" name="uuid">
              <Input />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "4px" }}
              label="邮箱"
              name="email"
            >
              <Input />
            </Form.Item>
          </>
        )}
        <Divider style={{ margin: "20px 0" }} />
        <div>
          <div style={{ fontWeight: 500 }}>
            告警规则(每种告警方式只能添加一条)
          </div>
          <table style={{ width: "100%" }} className={styles.table}>
            <thead>
              <tr>
                <th>告警方式</th>
                <th>告警金额</th>
                <th>可用天数</th>
                <th>是否开启</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {rules.map((rule) => {
                return (
                  <tr key={rule.uniqueId}>
                    <td>{rule.way === 1 ? "阈值" : "可用天数"}</td>
                    <td>{rule.way === 1 ? rule.threshold : "-"}</td>
                    <td>{rule.way === 2 ? `${rule.lastDays}` : "-"}</td>
                    <td>{rule.enable ? "开启" : "关闭"}</td>
                    <td>
                      <Button
                        type="link"
                        onClick={() => {
                          setEditRule({
                            ...rule,
                            isEdit: true,
                            type: "edit",
                          });
                        }}
                        size="small"
                      >
                        编辑
                      </Button>
                      <Button
                        type="link"
                        danger
                        onClick={() => {
                          setRules((pre) =>
                            pre.filter((r) => r.uniqueId !== rule.uniqueId)
                          );
                        }}
                        size="small"
                      >
                        删除
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!editRule.isEdit && (
            <Button
              type="primary"
              onClick={() => {
                setEditRule({
                  ...defaultRule,
                  uniqueId: nanoid(),
                  isEdit: true,
                  type: "add",
                });
              }}
              size="small"
            >
              添加
            </Button>
          )}
        </div>
        {editRule.isEdit && (
          <div
            style={{
              position: "relative",
              padding: "10px 20px",
              backgroundColor: "#f1f1f1",
              borderRadius: "4px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Radio.Group
                value={editRule.way}
                onChange={(e) => {
                  setEditRule((pre) => ({
                    ...pre,
                    way: e.target.value,
                  }));
                }}
              >
                <Radio value={1}>按阈值</Radio>
                <Radio value={2}>可用天数</Radio>
              </Radio.Group>
              {editRule.way === 1 && (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <span>阈值</span>
                  <InputNumber
                    controls={false}
                    placeholder="阈值"
                    value={editRule.threshold}
                    onChange={(value) => {
                      setEditRule((pre) => ({
                        ...pre,
                        threshold: value as number,
                      }));
                    }}
                  />
                </div>
              )}
              {editRule.way === 2 && (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <span>可用天数</span>
                  <InputNumber
                    controls={false}
                    value={editRule.lastDays}
                    onChange={(value) => {
                      setEditRule((pre) => ({
                        ...pre,
                        lastDays: value as number,
                      }));
                    }}
                  />
                  <Button
                    icon={<RedoOutlined />}
                    onClick={() => getAvgCost(editRule.lastDays ?? 0)}
                  ></Button>
                  {isLoading ? (
                    <Spin />
                  ) : (
                    <div>
                      过去 {editRule.lastDays} 天的消费: {avgCost || "-"}
                    </div>
                  )}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <span>是否开启</span>
                <Switch
                  checked={editRule.enable}
                  checkedChildren="开启"
                  unCheckedChildren="关闭"
                  onChange={(value) => {
                    setEditRule((pre) => ({
                      ...pre,
                      enable: value,
                    }));
                  }}
                />
              </div>
              <div>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (!editRule.threshold && editRule.way === 1) {
                        message.error("阈值不能为空");
                        return;
                      }
                      if (editRule.way === 2 && !editRule.lastDays) {
                        message.error("可用天数不能为空");
                        return;
                      }
                      // 整数
                      if (
                        editRule.way === 1 &&
                        !Number.isInteger(editRule.threshold)
                      ) {
                        message.error("阈值必须是整数");
                        return;
                      }
                      if (
                        editRule.way === 2 &&
                        !Number.isInteger(editRule.lastDays)
                      ) {
                        message.error("可用天数必须是整数");
                        return;
                      }
                      setEditRule((pre) => ({
                        ...pre,
                        isEdit: false,
                      }));
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const { isEdit, ...rule } = editRule;
                      if (editRule.type === "add") {
                        setRules((pre) => [...pre, rule]);
                      } else {
                        setRules((pre) => {
                          const newRule = pre.slice();
                          const index = newRule.findIndex(
                            (r) => r.uniqueId === rule.uniqueId
                          );
                          newRule[index] = rule;
                          return newRule;
                        });
                      }
                    }}
                    size="small"
                  >
                    保存
                  </Button>
                  <Button
                    type="default"
                    onClick={() => {
                      setEditRule({
                        ...defaultRule,
                        isEdit: false,
                        type: "add",
                      });
                    }}
                    size="small"
                  >
                    取消
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        )}
        <Divider style={{ margin: "20px 0" }} />
        <Form.Item label="每天提醒次数" name="alertLimitPerDay">
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item label="最小提醒间隔(分钟)" name="alertMinimum">
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item label="内部通知用户" name="internalSubscriber">
          <Select
            mode="multiple"
            allowClear
            showSearch
            filterOption={filterOption}
            options={Object.entries(Feishu_Uid_Map).map(([value, label]) => {
              return {
                value,
                label,
              };
            })}
          ></Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
