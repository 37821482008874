import { getVoucherUseDetail } from "@/api/beacon";
import { Button, Input, Modal, Table } from "antd";
import Big from "big.js";
import dayjs from "dayjs";
import { useState } from "react";

export default function VoucherUsageModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [params, setParams] = useState({
    email: "",
    phone: "",
    uuid: "",
  });
  const [data, setData] = useState({
    data: [],
    total: 0,
    totalBalance: 0,
  });
  const columns = [
    {
      title: "优惠券名称",
      dataIndex: "name",
    },
    {
      title: "优惠券金额",
      dataIndex: "originalValue",
      render: (amount: number) => Big(amount).div(10000).toString(),
    },
    {
      title: "剩余金额",
      dataIndex: "balance",
      render: (amount: number) => Big(amount).div(10000).toString(),
    },
    {
      title: "生效日期",
      dataIndex: "effectDate",
      render: (time: number) => dayjs.unix(time).format("YYYY-MM-DD"),
    },
    {
      title: "过期日期",
      dataIndex: "expiryDate",
      render: (time: number) => dayjs.unix(time).format("YYYY-MM-DD"),
    },
    {
      title: "使用范围",
      dataIndex: "businessTypes",
      render: (text: any) => text.join(","),
    },
    {
      title: "状态",
      dataIndex: "status",
      render: (status: string) => {
        if (status === "valid") {
          return "生效中";
        }
        return "已过期";
      },
    },
  ];
  const getVoucherUse = () => {
    getVoucherUseDetail(params).then((res: any) => {
      setData(res);
    });
  };
  return (
    <Modal
      title="用户优惠券使用详情"
      open={open}
      onCancel={onClose}
      width={1000}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        <Input
          placeholder="请输入客户邮箱"
          value={params.email}
          onChange={(e) => setParams({ ...params, email: e.target.value })}
        />
        <Input
          placeholder="请输入客户手机号"
          value={params.phone}
          onChange={(e) => setParams({ ...params, phone: e.target.value })}
        />
        <Input
          placeholder="请输入客户uuid"
          value={params.uuid}
          onChange={(e) => setParams({ ...params, uuid: e.target.value })}
        />
        <Button type="primary" onClick={getVoucherUse}>
          查询
        </Button>
      </div>
      <Table columns={columns} dataSource={data.data} pagination={false} />
    </Modal>
  );
}
