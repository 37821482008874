import { balanceFormat } from "@/utils";
import { Modal, InputNumber, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { saveCreditLimit } from "@/api/pricing";

const emptyCreditInfo = {
  userBalance: 0,
  voucher: {
    gpuInstance: 0,
    modelApi: 0,
    serverless: 0,
  },
  totalBalance: 0,
  credit: null as number | null,
  autoRecharge: null as any,
  uuid: "",
};

export function ConfigModal({
  open,
  onClose,
  editData,
}: {
  open: boolean;
  onClose: () => void;
  editData: any;
}) {
  const [loading, setLoading] = useState(false);
  const [creditInfo, setCreditInfo] = useState(emptyCreditInfo);

  const save = useCallback(() => {
    if (creditInfo.credit === null || creditInfo.credit === undefined) {
      message.error("请输入信控额度");
      return;
    }
    setLoading(true);
    saveCreditLimit({
      uuid: creditInfo.uuid,
      credit: creditInfo.credit * 100,
    })
      .then(() => {
        message.success("保存成功");
        setCreditInfo(emptyCreditInfo);
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [creditInfo, onClose]);

  useEffect(() => {
    if (open && editData) {
      setCreditInfo({
        ...editData,
        credit: balanceFormat(editData.credit),
      });
    } else {
      setCreditInfo(emptyCreditInfo);
    }
  }, [editData, open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={"信控配置"}
      onCancel={onClose}
      onOk={save}
      confirmLoading={loading}
    >
      <div>
        <table className={styles.credit_limit_table}>
          <tbody>
            <tr>
              <td>用户UUID</td>
              <td>{creditInfo?.uuid}</td>
            </tr>
            <tr>
              <td>用户余额</td>
              <td>{balanceFormat(creditInfo?.userBalance)}</td>
            </tr>

            <tr>
              <td>自动充值配置</td>
              <td>
                <div>
                  {creditInfo?.autoRecharge &&
                  creditInfo?.autoRecharge?.isAutoRecharge ? (
                    <div>
                      {`小于$${creditInfo?.autoRecharge?.threshold}, 自动充值到$${creditInfo?.autoRecharge?.rechargeAmount}`}
                    </div>
                  ) : (
                    "未开启"
                  )}
                </div>
              </td>
            </tr>

            <tr>
              <td>券余额</td>
              <td>
                <div>
                  <div>
                    GPU 券余额：
                    {balanceFormat(creditInfo?.voucher?.gpuInstance)}
                  </div>
                  <div>
                    Model API 券余额：
                    {balanceFormat(creditInfo?.voucher?.modelApi)}
                  </div>
                  <div>
                    Serverless 券余额：
                    {balanceFormat(creditInfo?.voucher?.serverless)}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>信控额度</td>
              <td>
                <InputNumber
                  value={creditInfo.credit}
                  onChange={(e) =>
                    setCreditInfo({
                      ...creditInfo,
                      credit: e,
                    })
                  }
                  controls={false}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
